import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';
import { ITeacherRecordNew } from '../interfaces/room.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export enum AttendanceType {
  teacher = 'user',
  teacherAuxiliary = 'roomauxiliarteacher',
  student = 'student'
}

//? ATTENDANCE
export const postCreateUniqueAttendance = async (attendance: any) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/attendances/`,
    attendance,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const patchAttendanceMutation = async (attendance: any) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/attendances/${attendance.id}/`,
    { status: attendance.status },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postCreateTeacherAttendance = async (
  teacherRecords: ITeacherRecordNew,
  room: any
) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/rooms/${room?.id}/attendances/teachers/`,
    teacherRecords,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getAttendanceByStudentV1 = async (id: number) => {
  try {
    const token = getAccessCookie();
    return await axios.get(
      `${instanceBaseURL}/backoffice/v2/rooms/${id}/attendances?type=student`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getAttendanceByStudent = async (id: number, getAll?: boolean) => {
  try {
    const token = getAccessCookie();
    const params = getAll ? `${id}/?all=true` : `${id}/`;
    return await axios.get(
      `${instanceBaseURL}/attendance-list/student/${params}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getAttendanceByTeacher = async (id: number, type = 'teacher') => {
  try {
    const token = getAccessCookie();
    return await axios.get(
      `${instanceBaseURL}/backoffice/v2/rooms/${id}/attendances?type=${type}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getListAbsenceReasons = async () => {
  const token = getAccessCookie();
  return await axios.get(
    `${instanceBaseURL}/catalog/reasons-absence-student/`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const patchReasonAbsence = async ({
  attendanceId,
  reasonId
}: {
  attendanceId: number;
  reasonId: string | number;
}) => {
  const token = getAccessCookie();
  const response = await axios.patch(
    `${instanceBaseURL}/attendances/${attendanceId}/absence-reason/`,
    { reason_id: reasonId },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};

export const patchBulkAttendanceToday = async ({
  entityType,
  sessionId
}: {
  entityType: 'student' | 'teacher' | 'auxiliar_teacher';
  sessionId: number;
}) => {
  const token = getAccessCookie();
  const response = await axios.patch(
    `${instanceBaseURL}/attendances-update/`,
    {
      entity_type: entityType,
      session_id: sessionId
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};
