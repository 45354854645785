import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getKnowledges,
  getSubknowledges,
  getEtarioTeacher,
  getCoursesTrained,
  postCreateTeacher,
  getAuxiliarTeacher,
  putTeacherProfileV2,
  postCheckAvailable,
  putTeacherAvailability,
  postTeacherAvailability,
  postCreateAuxiliarTeacher,
  patchChangeSessionSchedule
} from '../../services/teachers';

interface IAuthError {
  msg: string;
}

interface CreateTeacherState {
  teachers: any[];
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  totalCount: number;
}

export const addExternalTeacher = createAsyncThunk(
  'teachers/addExternalTeacher',
  async (
    data: any,
    { getState, rejectWithValue, dispatch, ...others }: any
  ) => {
    try {
      const response = await postCreateAuxiliarTeacher(data);
      toast.success('Se agregó el profesor correctamente');
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al agregar el profesor.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExternalTeacherByEmailFromApi = createAsyncThunk(
  'teachers/getExternalTeacherByEmail',
  async ({ email }: { email: string }, { rejectWithValue }: any) => {
    try {
      return await getAuxiliarTeacher(email);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addTeacher = createAsyncThunk(
  'teachers/addTeacher',
  async (
    data: any,
    { getState, rejectWithValue, dispatch, ...others }: any
  ) => {
    try {
      const response = await postCreateTeacher(data);
      toast.success('Se creó el profesor correctamente');
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al crear el profesor.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateTeacher = createAsyncThunk(
  'teachers/updateTeacher',
  async (teacher: { id: any; payload: any }, { rejectWithValue }: any) => {
    try {
      const { id, payload } = teacher;
      const response = await putTeacherProfileV2(id, payload);
      toast.success('Se actualizó el profesor correctamente');
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al editar el profesor.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAgeGroupFromApi = createAsyncThunk(
  'teachers/getAgeGroup',
  async ({ rejectWithValue }: any) => {
    try {
      return await getEtarioTeacher();
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCoursesTrainedFromApi = createAsyncThunk(
  'teachers/getCoursesTrained',
  async ({ rejectWithValue }: any) => {
    try {
      return await getCoursesTrained();
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getKnowledgesFromApi = createAsyncThunk(
  'teachers/getKnowledges',
  async ({ rejectWithValue }: any) => {
    try {
      return await getKnowledges();
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSubknowledgesFromApi = createAsyncThunk(
  'teachers/getSubknowledges',
  async ({ rejectWithValue }: any) => {
    try {
      return await getSubknowledges();
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateTeacherAvailability = createAsyncThunk(
  'teachers/updateTeacherAvailability',
  async (teacher: { id: any; payload: any }, { rejectWithValue }: any) => {
    try {
      const { id, payload } = teacher;
      const response = await putTeacherAvailability(id, payload);
      toast.success('Se actualizó el profesor correctamente');
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al editar el profesor.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addTeacherAvailability = createAsyncThunk(
  'teachers/addTeacherAvailability',
  async (
    teacher: { id: any; payload: any },
    { getState, rejectWithValue, dispatch, ...others }: any
  ) => {
    try {
      const { id, payload } = teacher;
      const response = await postTeacherAvailability(id, payload);
      toast.success('Se actualizó el profesor correctamente');
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al editar el profesor.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkTeacherAvailability = createAsyncThunk(
  'teachers/checkTeacherAvailability',
  async (
    data: any,
    { getState, rejectWithValue, dispatch, ...others }: any
  ) => {
    try {
      const response = await postCheckAvailable(data);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const changeSessionSchedule = createAsyncThunk(
  'teachers/changeSessionSchedule',
  async (teacher: { roomId: any; payload: any }, { rejectWithValue }: any) => {
    try {
      const { roomId, payload } = teacher;
      const response = await patchChangeSessionSchedule(roomId, payload);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: CreateTeacherState = {
  teachers: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** addExternalTeacher */
    builder.addCase(addExternalTeacher.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addExternalTeacher.fulfilled, (state, action) => {
      state?.teachers?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addExternalTeacher.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getExternalTeacherByEmailFromApi */
    builder.addCase(getExternalTeacherByEmailFromApi.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(
      getExternalTeacherByEmailFromApi.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getExternalTeacherByEmailFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** addTeacher */
    builder.addCase(addTeacher.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addTeacher.fulfilled, (state, action) => {
      state?.teachers?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addTeacher.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });

    /** updateTeacher */
    builder.addCase(updateTeacher.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(updateTeacher.fulfilled, (state, action) => {
      const payload = action.payload;
      const teachers = state.teachers.filter(
        teacher => teacher.id !== payload.id
      );
      state.teachers = [...teachers, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateTeacher.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getAgeGroupFromApi */
    builder.addCase(getAgeGroupFromApi.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(getAgeGroupFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getAgeGroupFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getCoursesTrainedFromApi */
    builder.addCase(getCoursesTrainedFromApi.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(getCoursesTrainedFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getCoursesTrainedFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getKnowledgesFromApi */
    builder.addCase(getKnowledgesFromApi.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(getKnowledgesFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getKnowledgesFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getSubknowledgesFromApi */
    builder.addCase(getSubknowledgesFromApi.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(getSubknowledgesFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getSubknowledgesFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** updateTeacherAvailability */
    builder.addCase(updateTeacherAvailability.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(updateTeacherAvailability.fulfilled, (state, action) => {
      const payload = action.payload;
      const teachers = state.teachers.filter(
        teacher => teacher.id !== payload.id
      );
      state.teachers = [...teachers, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateTeacherAvailability.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** addTeacherAvailability */
    builder.addCase(addTeacherAvailability.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addTeacherAvailability.fulfilled, (state, action) => {
      state?.teachers?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addTeacherAvailability.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** checkTeacherAvailability */
    builder.addCase(checkTeacherAvailability.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(checkTeacherAvailability.fulfilled, (state, action) => {
      state?.teachers?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(checkTeacherAvailability.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** changeSessionSchedule */
    builder.addCase(changeSessionSchedule.pending, (state, _) => {
      state.teachers = initialState.teachers;
      state.isLoading = true;
    });
    builder.addCase(changeSessionSchedule.fulfilled, (state, action) => {
      const payload = action.payload;
      const teachers = state.teachers.filter(
        teacher => teacher.id !== payload.id
      );
      state.teachers = [...teachers, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(changeSessionSchedule.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateTeachers = (state: any) => state.teachers;
