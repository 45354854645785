import * as Yup from 'yup';

export const ScheduleChangeSchema = Yup.object().shape({
  perioricity: Yup.string().required('Campo obligatorio'),
  perWeekSelectedDay1: Yup.string().required('Campo obligatorio'),
  perWeekSelectedInitHour1: Yup.object()
    .shape({
      minutes: Yup.string().required('Campo obligatorio'),
      hour: Yup.string().required('Campo obligatorio')
    })
    .nullable()
    .required(),
  perWeekSelectedDuration1: Yup.string().required('Campo obligatorio'),
  initDate: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  endDate: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  teacherId: Yup.string().required('Campo obligatorio'),
  // assignAsMainTeacher: Yup.boolean().required('Campo obligatorio')
});
