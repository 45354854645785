import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';
import { News } from '../interfaces/news.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const patchNews = async (
  notificationId: number,
  status: News['status']
) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/notification/update/status/`,
    {
      user_notification_id: notificationId,
      status
    },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getNews = async ({ status, context }: News) => {
  try {
    const token = getAccessCookie();
    return await axios.get(
      `${instanceBaseURL}/notification/detail/${status}/${context}/`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
