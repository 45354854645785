import './loader.scss';

interface Props {
  customClassName?: string;
}

const Loader: React.FC<Props> = ({ customClassName }) => {
  return <div className={`loader ${customClassName}`}></div>;
};

export default Loader;
