// Material UI
import Box from '@mui/material/Box';

import './../../../rooms.scss';
import { AttendanceStudent } from './AttendanceStudent';
import {
  IRoomDetailSimplified,
  IScheduleGroup
} from '../../../../../interfaces/room.interface';

interface Props {
  refetch: () => void;
  room: IRoomDetailSimplified;
  refetchDetailHeader: () => void;
  fetchStatusStudentsByRoom: (roomId: number) => void;
  qtySessions: any;
  scheduleByRoom: IScheduleGroup;
}

export const Students: React.FC<Props> = ({
  refetch,
  room,
  refetchDetailHeader,
  fetchStatusStudentsByRoom,
  qtySessions,
  scheduleByRoom
}) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginBottom: '1em',
          paddingBottom: '1em'
        }}
      >
        <AttendanceStudent
          roomInfo={room}
          refetch={refetch}
          refetchDetailHeader={refetchDetailHeader}
          fetchStatusStudentsByRoom={fetchStatusStudentsByRoom}
          qtySessions={qtySessions}
          scheduleByRoom={scheduleByRoom}
        />
      </Box>
    </>
  );
};
