import { useState, useEffect } from 'react';

import { getNews } from '../services/news';
import { News } from '../interfaces/news.interface';

const useShowNewsModal = ({
  status,
  context
}: {
  status: News['status'];
  context: News['context'];
}) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchNews = async ({ status, context }: News) => {
    try {
      const response = await getNews({ status, context });
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      console.error('Error fetching news data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews({ status, context });
  }, []);

  return {
    data,
    isLoading,
    isError,
    fetchNews
  };
};

export default useShowNewsModal;
