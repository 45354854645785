/* eslint-disable complexity */
/* eslint-disable react/no-children-prop */
import { toast } from 'react-hot-toast';
import React, { useState, useEffect } from 'react';

import './modalInactiveStudentsReasons.scss';

import Modal from '../../atoms/Modal';
import Select from '../../molecules/Select/Select';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';
import { patchChangeEnrollmentState } from '../../../services/students';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  reasonOptions: any[];
  studentName: string;
  studentId: string;
  roomId: number;
  refetch: any;
  studentState: any;
  inactiveStudentReason?: any;
  refetchStatusStudentsByRoom: (roomId: number) => void;
}

const ModalInactiveStudentsReasons: React.FC<Props> = ({
  width,
  roomId,
  isOpen,
  refetch,
  studentId,
  studentName,
  studentState,
  reasonOptions,
  inactiveStudentReason: initialInactiveStudentReason,
  closeModal,
  refetchStatusStudentsByRoom
}) => {
  const studentStates = [
    {
      name: 'Activo',
      id: 'done',
      order: 1
    },
    {
      name: 'Riesgo',
      id: 'risk',
      order: 2
    },
    {
      name: 'Inactivo',
      id: 'inactive',
      order: 3
    }
  ];
  const [isOpenSelectState, setIsOpenSelectState] = useState(false);
  const [isOpenSelectEtario, setIsOpenSelectEtario] = useState(false);
  const [isInactiveState, setIsInactiveState] = useState(
    studentState === 'inactive'
  );

  const [inactiveStudentReason, setInactiveStudentReason] = useState<any>(null);

  const [newStudentState, setNewStudentState] = useState<any>(null);
  const [studentReasonOptions, setStudentReasonOptions] = useState<any>();

  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);

  const handleClickValueSelectedState = (option: any): void => {
    setNewStudentState(option);
    setIsInactiveState(option.id === 'inactive');
    setInactiveStudentReason(null);
  };

  const handleClickValueSelected1 = (option: any): void => {
    setInactiveStudentReason(option);
  };

  const handleCloseModal = () => {
    setIsInactiveState(false);
    closeModal();
    setInactiveStudentReason(null);
    setNewStudentState(studentStates.find((e: any) => e.id === studentState));
  };

  const handleSaveModal = () => {
    if (newStudentState.id === 'inactive' && !inactiveStudentReason) {
      toast.error('Debe seleccionar una opción');
      return;
    }

    setIsInactiveState(false);
    closeModal();
    setInactiveStudentReason(null);
    save();
  };

  const save = async () => {
    const payload = {
      student_id: studentId,
      room_id: roomId,
      state: newStudentState.id,
      reason_id: inactiveStudentReason?.id || null
    };
    const data = await patchChangeEnrollmentState(payload);
    if (data && data?.status === 200) {
      setIsSuccessAlert(true);
      setTimeout(() => {
        setIsSuccessAlert(false);
      }, 5000);
      refetch();
      refetchStatusStudentsByRoom(roomId);
    } else {
      setIsErrorAlert(true);
      setTimeout(() => {
        setIsErrorAlert(false);
      }, 5000);
    }
  };

  useEffect(() => {
    setIsInactiveState(studentState === 'inactive');
    if (initialInactiveStudentReason) {
      const res = reasonOptions.find(
        (e: any) => e.name === initialInactiveStudentReason
      );
      setInactiveStudentReason(res);
      return;
    }
  }, [isOpen]);

  useEffect(() => {
    const res = studentStates.find((e: any) => e.id === studentState);
    setNewStudentState(res);
  }, [studentState]);

  useEffect(() => {
    setStudentReasonOptions(
      reasonOptions?.map((et: any, ind: any) => ({
        id: ind + 1,
        name: et.name
      }))
    );
  }, [reasonOptions]);

  const isInactiveStudentState =
    newStudentState?.id === 'inactive' &&
    newStudentState?.id === studentState &&
    !inactiveStudentReason;

  const studentReasonLabel = isInactiveStudentState
    ? initialInactiveStudentReason
    : inactiveStudentReason?.name
      ? inactiveStudentReason?.name
      : 'Seleccionar motivo';

  const shouldDisableButton =
    (newStudentState?.id === studentState &&
      newStudentState?.id !== 'inactive') ||
    (newStudentState?.id === 'inactive' && !inactiveStudentReason) ||
    (newStudentState?.id === 'inactive' &&
      initialInactiveStudentReason === inactiveStudentReason.name);

  return (
    <>
      <Modal
        key={studentName}
        className="modal-add-teacher-etario"
        width={width}
        isOpen={isOpen}
        closeModal={closeModal}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            Selecciona el estado del alumno
          </Typography>
        }
        subtitle={
          <Typography type="paragraph2" variation="mobile">
            Selecciona el estado al que pasará a estar {studentName}
          </Typography>
        }
        body={
          <div className="modal-add-teacher-etario__container-body">
            <>
              <section>
                <Typography
                  className="modal-add-teacher-etario__label-habilities"
                  bold
                  type="paragraph2"
                  variation="mobile"
                >
                  Estado
                </Typography>
              </section>
              <section className="modal-add-teacher-etario__habilities">
                <Select
                  key={studentName}
                  sortBy="order"
                  useSortBy={true}
                  addIsScroll="none"
                  itemsDropdown={0}
                  className={
                    newStudentState
                      ? 'teacher-profile__select'
                      : 'teacher-profile__select-placeholder'
                  }
                  setIsOpen={setIsOpenSelectState}
                  isOpen={isOpenSelectState}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) =>
                    handleClickValueSelectedState(event)
                  }
                  disabled={!studentStates}
                  options={studentStates}
                  children={
                    newStudentState?.id
                      ? newStudentState?.name
                      : 'Seleccionar estado'
                  }
                />
              </section>
            </>
            {isInactiveState && (
              <>
                <section>
                  <Typography
                    className="modal-add-teacher-etario__label-habilities"
                    bold
                    type="paragraph2"
                    variation="mobile"
                  >
                    Motivo
                  </Typography>
                </section>
                <section className="modal-add-teacher-etario__habilities">
                  <Select
                    key={studentName}
                    sortBy="id"
                    useSortBy={true}
                    addIsScroll="none"
                    itemsDropdown={0}
                    className={
                      inactiveStudentReason
                        ? 'teacher-profile__select'
                        : 'teacher-profile__select-placeholder'
                    }
                    setIsOpen={setIsOpenSelectEtario}
                    isOpen={isOpenSelectEtario}
                    size="default"
                    dividerItems
                    onclickDefaultValue={(event: any) =>
                      handleClickValueSelected1(event)
                    }
                    disabled={!studentReasonOptions}
                    options={studentReasonOptions}
                    children={studentReasonLabel}
                  />
                </section>
              </>
            )}
          </div>
        }
        buttons={
          <>
            <ButtonOutline
              type="reset"
              size="default"
              width="8rem"
              onClick={handleCloseModal}
            >
              Cancelar
            </ButtonOutline>
            <ButtonFilled
              disabled={shouldDisableButton}
              size="default"
              width="8rem"
              onClick={handleSaveModal}
            >
              Confirmar
            </ButtonFilled>
          </>
        }
      />

      {isSuccessAlert && (
        <AlertSnackbar severity="success">
          <Typography type="paragraph2" variation="mobile">
            Cambiaste correctamente el estado de <strong>{studentName}</strong>
          </Typography>
        </AlertSnackbar>
      )}

      {isErrorAlert && (
        <AlertSnackbar severity="error">
          <Typography type="paragraph2" variation="mobile">
            Ocurrió un error al cambiar el estado de{' '}
            <strong>{studentName}</strong>
          </Typography>
        </AlertSnackbar>
      )}
    </>
  );
};

export default ModalInactiveStudentsReasons;
