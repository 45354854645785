import React from 'react';
import { Icon, Typography } from 'ctc-component-library';

import './redirect.scss';
import Loader from '../../atoms/Loader/Loader';
import IconLogo from '../../atoms/icons/IconLogo';

interface Props {
  iconName: string;
  title: string;
  description: string;
  note?: string | null;
}

const Redirect: React.FC<Props> = ({
 iconName, title, description, note 
}) => {
  return (
    <div className="redirect">
      <div className="redirect__header">
        <IconLogo className="redirect__logo" />
      </div>
      <div className="redirect__body">
        <div className="redirect__content">
          {iconName === 'loader' ? (
            <Loader customClassName="redirect__icon" />
          ) : (
            <Icon customClassName="redirect__icon" iconName={iconName} />
          )}
          <Typography className="redirect__title" variant="h4">
            {title}
          </Typography>
          <Typography className="redirect__description" variant="body4">
            {description}
          </Typography>
          {note ? (
            <Typography className="redirect__note" variant="body4">
              {note}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Redirect;
