/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Typography, colors, Button } from 'ctc-component-library';

import './navRoomDetail.scss';
import {
  getListOverdueReasons,
  postOverdueContentReport
} from '../../../../../services/b2b/overdueContent';
import { RootState } from '../../../../../store/store';
import { useModal } from '../../../../../hooks/useModal';
import SelectComp from '../../../../../components/molecules/SelectComp';
import TypographyBO from '../../../../../components/atoms/Typography/Typography';
import { getHasPendingReport } from '../../../../../services/b2b/overdueContent';
import ModalMessageAlert from '../../../../../components/molecules/ModalMessageAlert';
import { IRoomDetailHeaderSimplified } from '../../../../../interfaces/room.interface';
import {
  ADMIN,
  TEACHER_AUXILIARY
} from '../../../../../constants/usersType.constants';
import ButtonFilled from '../../../../../components/molecules/ButtonFilled/ButtonFilled';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline/ButtonOutline';
import { AlertSnackbar } from '../../../../../components/common/AlertSnackbar/AlertSnackbar';
import ModalReportOverdueContent from '../../../../../components/molecules/ModalReportOverdueContent';
import ModalDetailReportOverdueContent from '../../../../../components/molecules/ModalDetailReportOverdueContent';

interface Props {
  dataRoomHeader?: IRoomDetailHeaderSimplified;
}

type QtyClases = {
  value: string;
  name: string;
};
type ReasonOverdue = {
  id: number;
  name: string;
};
type RoomDetail = {
  label: string;
  value: string | number | boolean | null | undefined;
  icon?: boolean;
};

const NavRoomDetail: React.FC<Props> = ({ dataRoomHeader }) => {
  const navigate = useNavigate();
  const showEye = true;
  const { roomId } = useParams();
  const user = useSelector((state: RootState) => state.auth.user);
  const isAdmin = user?.permission.name === ADMIN;
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [detailReportByRoom, setDetailReportByRoom] = useState();
  const [isPendingReport, setIsPendingReport] = useState<boolean>(false);
  const [qtyClases, setQtyClases] = useState<QtyClases>({
    value: '',
    name: ''
  });
  const [reasonOverdue, setReasonOverdue] = useState<ReasonOverdue>({
    id: 0,
    name: ''
  });
  const [listreasonOverdue, setListReasonOverdue] = useState<any[]>();

  const {
    openModal: openModalReportContentDelay,
    closeModal: closeModalReportContentDelay,
    isOpenModal: isOpenModalReportContentDelay
  } = useModal();

  const {
    openModal: openModalAlert,
    closeModal: closeModalAlert,
    isOpenModal: isOpenModalAlert
  } = useModal();

  const {
    openModal: openModalDetailReport,
    closeModal: closeModalDetailReport,
    isOpenModal: isOpenModalDetailReport
  } = useModal();

  const dataClases = [
    { value: '2', name: '2 clases' },
    { value: '3', name: '3 clases' },
    { value: '+4', name: '4 o más clases' }
  ];

  useEffect(() => {
    hasPendingReport();
  }, []);

  const handleClickQtyClasses = async (option: any) => {
    setQtyClases(option);
  };

  const handleClickReasonsOverdue = async (option: any) => {
    setReasonOverdue(option);
  };

  const hasPendingReport = async () => {
    try {
      const { data } = await getHasPendingReport(Number(roomId));
      setDetailReportByRoom(data);
      if (data && data?.status === 'pending') {
        setIsPendingReport(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listReasons = async () => {
    try {
      const response = await getListOverdueReasons();
      setListReasonOverdue(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendReportOverdue = async (
    roomId: number,
    count: string,
    reasonId: string | number
  ) => {
    try {
      const response = await postOverdueContentReport({
        roomId,
        count,
        reasonId
      });
      if (response.status === 201) {
        closeModalReport();
        openModalAlert();
        hasPendingReport();
      } else {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    }
  };

  const closeModalReport = () => {
    closeModalReportContentDelay();
    setQtyClases({ value: '', name: '' });
    setReasonOverdue({ id: 0, name: '' });
  };

  const goToSchedule = () => {
      navigate(`/dashboard/rooms/schedule/${roomId}/edit`);
  };

  return (
    <>
      <div className="nav-room-detail-v2__fixed-header">
        <div>
          <Button
            customBtnClassName="nav-room-detail-v2__button-back"
            icon="arrow_back"
            iconPosition="left"
            type="tertiary"
            text="Regresar a Salones"
            onClick={() => navigate('/dashboard/rooms')}
            customStyles={{ width: 191, marginLeft: '-15px' }}
          />
          <Typography
            className="nav-room-detail-v2__title"
            variant="h4"
            customStyles={{ fontWeight: '300', color: colors.neutrals.Gray80 }}
          >
            {dataRoomHeader?.name}
          </Typography>
        </div>

        {/* <div className="nav-room-detail-v2__container-title">
          <div className="nav-room-detail-v2__container-text-btn">
            <Typography
              className="nav-room-detail-v2__container-text-btn-icon"
              variant="body4"
              customStyles={{ color: colors.neutrals.Gray60 }}
            >
              Huso horario: <strong>GMT-5</strong>
            </Typography>
            {isAdmin && (
              <div className="nav-room-detail-v2__container-text-btn-btn">
                <Button
                  icon="calendar_today"
                  iconPosition="right"
                  type="tertiary"
                  disabled={false}
                  onClick={() => openModalDetailReport()}
                  customBtnClassName="nav-room-detail-v2__container-text-btn-btn-icon"
                />
              </div>
            )}
          </div>
          <Button
            type="tertiary"
            text="⚠️ Reportar atraso de contenido"
            disabled={false}
            onClick={() => {
              openModalReportContentDelay();
              listReasons();
            }}
            customBtnClassName="nav-room-detail-v2__container-text-btn-add-report"
          />
          <div className="nav-room-detail-v2__container-text-btn nav-room-detail-v2__container-text-btn-show-report">
            <Typography
              className="nav-room-detail-v2__container-text-btn-icon"
              variant="body4"
              customStyles={{ color: colors.neutrals.Gray60 }}
            >
              Reporte atraso de contenido
            </Typography>
            <Button
              icon="visibility"
              iconPosition="right"
              type="tertiary"
              disabled={false}
              onClick={() => openModalDetailReport()}
            />
          </div>
        </div> */}

        <div className="nav-room-detail-v2__container-title">
          <div className="nav-room-detail-v2__container-text-btn">
            <Typography
              className="nav-room-detail-v2__container-text-btn-icon"
              variant="body4"
              customStyles={{ color: colors.neutrals.Gray60 }}
            >
              Huso horario: <strong>GMT-5</strong>
            </Typography>
            {isAdmin && (
              <div className="nav-room-detail-v2__container-text-btn-btn">
                <Button
                  icon="calendar_today"
                  iconPosition="right"
                  type="tertiary"
                  disabled={false}
                  onClick={goToSchedule}
                  customBtnClassName="nav-room-detail-v2__container-text-btn-btn-icon"
                />
              </div>
            )}
          </div>
          {!isAdmin &&
          !isPendingReport &&
          user?.permission.name !== TEACHER_AUXILIARY ? (
            <Button
              type="tertiary"
              text="⚠️ Reportar atraso de contenido"
              disabled={false}
              onClick={() => {
                openModalReportContentDelay();
                listReasons();
              }}
              customBtnClassName="nav-room-detail-v2__container-text-btn-add-report"
            />
          ) : null}
          {isPendingReport && (
            <div className="nav-room-detail-v2__container-text-btn nav-room-detail-v2__container-text-btn-show-report">
              <Typography
                className="nav-room-detail-v2__container-text-btn-icon"
                variant="body4"
                customStyles={{ color: colors.neutrals.Gray60 }}
              >
                Reporte atraso de contenido
              </Typography>
              <Button
                icon="visibility"
                iconPosition="right"
                type="tertiary"
                disabled={false}
                onClick={() => openModalDetailReport()}
              />
            </div>
          )}
        </div>
      </div>

      <ModalReportOverdueContent
        width="lg"
        title="Reportar atraso de contenido"
        subtitle={
          <>
            Para que podamos ayudarte, selecciona la cantidad de clases
            atrasadas (redondeando para arriba) y el motivo principal. <br />{' '}
            <b>
              {' '}
              Recuerda que debes notificar sólo si van 2 o más clases con
              atraso.
            </b>
          </>
        }
        body={
          <form>
            <div className="room-detail-header-v2__modal-container">
              <Typography variant="body5" customStyles={{ fontWeight: 'bold' }}>
                Clases atrasadas*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickQtyClasses(option)
                }
                options={dataClases}
                dividerItems
                value={qtyClases}
                placeholder="Seleccionar cantidad de clases atrasadas"
              />

              <Typography variant="body5" customStyles={{ fontWeight: 'bold' }}>
                Motivo principal*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickReasonsOverdue(option)
                }
                options={listreasonOverdue}
                dividerItems
                value={reasonOverdue}
                placeholder="Seleccionar motivo principal"
              />
            </div>
          </form>
        }
        message="Se agrego correctamente el nuevo profesor..."
        isOpenModal={isOpenModalReportContentDelay}
        openModal={openModalReportContentDelay}
        closeModal={closeModalReportContentDelay}
        button1={
          <ButtonOutline size="default" onClick={closeModalReport}>
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            disabled={!qtyClases?.value || !reasonOverdue?.id || !dataRoomHeader?.id}
            onClick={
              qtyClases?.value && reasonOverdue?.id && dataRoomHeader
                ? () =>
                    handleSendReportOverdue(
                      dataRoomHeader?.id,
                      qtyClases?.value,
                      reasonOverdue?.id
                    )
                : () => {}
            }
          >
            Reportar
          </ButtonFilled>
        }
      />
      <ModalDetailReportOverdueContent
        information={detailReportByRoom}
        isAdmin={isAdmin}
        isStatusVisible={true}
        isOpenModal={isOpenModalDetailReport}
        openModal={openModalDetailReport}
        closeModal={closeModalDetailReport}
        button1={
          <ButtonFilled size="default" onClick={() => closeModalDetailReport()}>
            Aceptar
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        className="table-attendance-student__modal-alert"
        width="sm"
        align="center"
        title={
          <TypographyBO bold type="h1" align="center" variation="mobile">
            ¡Gracias por reportarlo!
          </TypographyBO>
        }
        isOpenModal={isOpenModalAlert}
        openModal={openModalAlert}
        closeModal={closeModalAlert}
        body={
          <TypographyBO align="center" type="paragraph1" variation="mobile">
            Nos pondremos en contacto para encontrar una solución y poder llegar
            a dar todo el contenido.
          </TypographyBO>
        }
        button1={
          <ButtonFilled fullWidth onClick={closeModalAlert}>
            Aceptar
          </ButtonFilled>
        }
      />
      {isErrorAlert ? (
        <AlertSnackbar
          message="Ocurrió un error al enviar la notificación de atraso de contenido. Vuelve a intentar"
          severity="error"
        />
      ) : null}
    </>
  );
};

export default NavRoomDetail;
