import { useState, useEffect } from 'react';

import { getStatusStudentsByRoom } from '../services/students';

const useStatusStudentsByRoom = ({ roomId }: { roomId: number }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchStatusStudentsByRoom = async (roomId: number) => {
    try {
      const response = await getStatusStudentsByRoom(roomId);
      setData(response);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      console.error('Error fetching news data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusStudentsByRoom(roomId);
  }, []);

  return {
    data,
    isLoading,
    isError,
    fetchStatusStudentsByRoom
  };
};

export default useStatusStudentsByRoom;
