/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, CSSProperties } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link as LinkRouter } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import './tableRoom.scss';
import 'moment/locale/es';
import colors from '../../atoms/Colors';
import {
  getAllRooms,
  selectStateRooms,
  updateRoomZoomLink
} from '../../../slices/roomSlice/roomsSlice';
import {
  ADMIN,
  TEACHER_CTC,
  TEACHER_AUXILIARY
} from '../../../constants/usersType.constants';
import { useModal } from '../../../hooks/useModal';
import TagCourses from '../../molecules/TagCourses';
import Link from '../../../components/molecules/Link';
import Table from '../../../components/molecules/Table';
import ButtonLineal from '../../molecules/ButtonLineal';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import ItemFloatingMenu from '../../molecules/ItemFloatingMenu';
import PERMISSIONS from '../../../constants/permission.constants';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import FloatingMenu from '../../../components/molecules/FloatingMenu';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import IconZoomColor from '../../../components/atoms/icons/IconZoomColor';
import TagStatusGroups from '../../../components/molecules/TagStatusGroups';
import { getQualificationForTeacher } from '../../../services/qualifications';
import IconAlertCircleOutlined from '../../atoms/icons/IconAlertCircleOutlined';
import ModalMessageAlert from '../../molecules/ModalMessageAlert/ModalMessageAlert';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../../constants/roomList.constants';
import TagStatusEvaluation from '../../molecules/TagStatusEvaluation/TagStatusEvaluation';

interface Props {
  user: any;
}

const TableRoom: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paramsURL = new URLSearchParams(location.search);
  const paramProjectId = paramsURL.get('projectId');
  const dispatch: AppDispatch = useAppDispatch();
  const { rooms, isLoading, count, totalPages } = useSelector(selectStateRooms);

  const [stateQualifications, setStateQualifications] = useState(false);

  const {
    openModal: openModalEvaluation,
    closeModal: closeModalEvaluation,
    isOpenModal: isOpenModalEvaluation
  } = useModal();

  const size = 100;
  const userId = user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id;
  const teacherCTC = user?.permission?.name === TEACHER_CTC;
  const teacherAuxiliary = user?.permission?.name === TEACHER_AUXILIARY;
  const userAdmin = user?.permission[PERMISSIONS.roomListAll];
  const instanceBaseURL = process.env.REACT_APP_CTC_BACKOFFICE_URL;

  const [filters, setFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  type FilterState = {
    [key: string]: string;
  };

  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );
  const [localCurrentFilters, setLocalCurrentFilters] = useState<any>([]);
  const [finalLocalCurrentFilters, setFinalLocalCurrentFilters] = useState<any>(
    []
  );

  /* const handleRefreshMeetingLink = async (zoomId: any) => {
    await dispatch(updateRoomZoomLink(zoomId));
  }; */

  const handleCopyZoomUrl = (props: any, teacherAuxiliary: any) => {
    const type = teacherAuxiliary ? 'student' : 'teacher';
    const roomId = props.row.original.id;
    const url = `${instanceBaseURL}/class-redirect?room=${roomId}&type=${type}`;
    toast.success('URL de Zoom copiado...');
    navigator.clipboard.writeText(url);
  };

  const resetFilter = (value: string) => {
    const newStateCurrent = { ...currentFilters };
    delete newStateCurrent[value];
    setCurrentFilters(newStateCurrent);

    const newStateLocal = localCurrentFilters.filter(
      (filter: any) => !filter.hasOwnProperty(value)
    );
    setLocalCurrentFilters(newStateLocal);

    if (newStateLocal.length === 0) {
      setFinalLocalCurrentFilters([]);
    }

    setCurrentPage(1);
  };

  type InputArray = Array<{ [key: string]: string }>;
  type FinalArray = { [key: string]: string };

  const transformArray = (inputArray: InputArray): FinalArray => {
    const finalArray: FinalArray = {};

    inputArray.forEach(item => {
      const key = Object.keys(item)[0];
      const value = item[key];

      switch (key) {
        case 'id':
          finalArray['id'] = value;
          break;
        case 'group.name':
          finalArray['group.name'] = value;
          break;
        case 'teacher.full_name':
          finalArray['teacher.full_name'] = value;
          break;
        case 'b2b_project':
          finalArray['b2b_project'] = value;
          break;
        case 'day_sessions':
          finalArray['day_sessions'] = value;
          break;
        case 'end_date_evaluation':
          finalArray['end_date_evaluation'] = value;
          break;
        case 'status_evaluation':
          finalArray['status_evaluation'] = value;
          break;
        case 'sort':
          finalArray['sort'] = value;
          break;
        case 'direction':
          finalArray['direction'] = value;
          break;
        default:
          finalArray['id'] = value;
      }
    });

    return finalArray;
  };

  const hasFilters = localCurrentFilters.length > 0;

  useEffect(() => {
    if (!hasFilters && !paramProjectId) {
      dispatch(getAllRooms({ userId, currentPage, size }));
    } else if (!hasFilters && paramProjectId) {
      const paramFilter = { b2b_project: paramProjectId };
      setCurrentFilters(paramFilter);
      setLocalCurrentFilters([paramFilter]);
      setFinalLocalCurrentFilters([paramFilter]);
      paramsURL.delete('projectId');
      navigate(`${location.pathname}?${paramsURL.toString()}`, {
        replace: true
      });
      dispatch(
        getAllRooms({
          userId,
          currentPage,
          size,
          project: paramProjectId,
          fetchAll: true
        })
      );
    }
  }, [currentPage, currentFilters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length < 3) {
        return;
      }
      if (
        currentFilters?.b2b_project &&
        currentFilters?.b2b_project?.length < 1
      ) {
        return;
      }

      const isFilterLengthValid = (
        filterKey: string,
        maxLength: number
      ): boolean => {
        return (
          currentFilters.hasOwnProperty(filterKey) &&
          currentFilters[filterKey]?.length < maxLength
        );
      };

      if (
        isFilterLengthValid('group.name', 3) ||
        isFilterLengthValid('teacher.full_name', 3)
      ) {
        return;
      }

      if (
        currentFilters?.status_evaluation &&
        currentFilters?.status_evaluation.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.end_date_evaluation &&
        currentFilters?.end_date_evaluation.length < 8
      ) {
        return;
      }
      
      const transformedCurrentFilters = transformArray(localCurrentFilters);
      setFinalLocalCurrentFilters(transformedCurrentFilters);
      dispatch(
        getAllRooms({
          userId,
          currentPage,
          size,
          daySession: transformedCurrentFilters?.day_sessions,
          roomId: transformedCurrentFilters?.id,
          project: transformedCurrentFilters?.b2b_project,
          group: transformedCurrentFilters['group.name'],
          teacher: transformedCurrentFilters['teacher.full_name'],
          end_date_evaluation: transformedCurrentFilters?.end_date_evaluation,
          status_evaluation: transformedCurrentFilters?.status_evaluation,
          sort: transformedCurrentFilters?.sort,
          direction: transformedCurrentFilters?.direction
        })
      );
    }
  }, [currentPage, filters]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
  }, [currentFilters]);

  const getCellStyle = (cell: any, userAdmin: boolean) => {
    const isPending = cell.row.original.status_evaluation === 'pending';
    const hasEndDate = cell.row.original.end_date_evaluation !== null;
    const isAccepted = isPending && hasEndDate && !userAdmin;
    const cellStyle: CSSProperties = {
      color: isAccepted ? colors.statusAbsenceText : 'black'
    };
    return cellStyle;
  };

  const customCellGroupNameOrId = ({ cell }: any) => {
    const cellStyle = getCellStyle(cell, userAdmin);
    return (
      <Tooltip title={cell.value} placement="top">
        <span style={cellStyle}>{cell.value}</span>
      </Tooltip>
    );
  };

  const customCellDates = ({ cell }: any) => (
    <>{cell.value ? moment(cell.value).format('DD-MM-YY') : ''}</>
  );

  const customCellTeacherName = ({ cell }: any) => (
    <Tooltip title={cell.value} placement="top">
      <span>{cell.value}</span>
    </Tooltip>
  );

  const customCellB2bProject = ({ cell }: any) => {
    const formattedValue = Array.isArray(cell.value)
      ? cell.value.join(', ')
      : cell.value;
    return (
      <Tooltip title={formattedValue} placement="top">
        <span>{formattedValue}</span>
      </Tooltip>
    );
  };

  const customCellEvaluationTag = ({ cell }: any) => (
    <TagStatusEvaluation status={cell.value}>{cell.value}</TagStatusEvaluation>
  );

  const customCellEvaluationDate = ({ cell }: any) => {
    const cellStyle = getCellStyle(cell, userAdmin);
    return (
      <div style={cellStyle}>
        {cell.value != null ? moment(cell.value).format('DD-MM-YY') : ''}
      </div>
    );
  };

  const customCellStatusTag = ({ cell }: any) => (
    <TagStatusGroups status={cell.value}>{cell.value}</TagStatusGroups>
  );

  const customCellDaySession = ({ cell }: any) => {
    const formattedValue = Array.isArray(cell.value)
      ? cell.value.join(', ')
      : cell.value;
    return (
      <Tooltip
        title={formattedValue}
        placement="top-start"
        classes={{ popper: 'table-room-tooltip-day-popper-class' }}
      >
        <span>{formattedValue}</span>
      </Tooltip>
    );
  };

  const customCellGoTo = (props: any) => {
    return (
      <>
        <Link
          className="table-room__link table-room__link--hover-lineal"
          to={`/dashboard/rooms/${props.row.original.id}`}
        >
          Ver salón
        </Link>
        {props.row.original.zoom_data?.zoom_id ? (
          <button
            className="table-guardian__container-tooltip"
            style={{ backgroundColor: 'inherit', border: 'none' }}
            onClick={() => {
              const type = teacherAuxiliary ? 'student' : 'teacher';
              const roomId = props.row.original.id;

              if (roomId) {
                window.open(
                  `/class-redirect?room=${roomId}&type=${type}`,
                  '_blank'
                );
              }
            }}
          >
            <IconZoomColor
              className={`table-room__button table-room__button--enabled`}
              fill="#237FE8"
            />
            <section className="table-guardian__tooltip table-guardian__tooltip--zoom">
              {props.column.tooltipValue}
            </section>
          </button>
        ) : (
          <div className="table-guardian__container-tooltip">
            <IconZoomColor
              className={`table-room__button table-room__button--disabled`}
              fill="#bcbcbc"
            />
            <section className="table-guardian__tooltip table-guardian__tooltip--zoom">
              {props.column.tooltipValue}
            </section>
          </div>
        )}
        <FloatingMenu
          items={
            user?.permission?.name === ADMIN
              ? [
                  <ItemFloatingMenu
                    key="item-floating-menu-1"
                    onClick={() => handleCopyZoomUrl(props, teacherAuxiliary)}
                  >
                    Copiar URL de Zoom
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu key="item-floating-menu-2">
                    <LinkRouter
                      to={`/dashboard/groups/${props.row.original.group.id}/rooms`}
                    >
                      Ver Grupo
                    </LinkRouter>
                  </ItemFloatingMenu>
                ]
              : [
                  <ItemFloatingMenu
                    key="item-floating-menu-1"
                    onClick={() => handleCopyZoomUrl(props, teacherAuxiliary)}
                  >
                    Copiar URL de Zoom
                  </ItemFloatingMenu>
                ]
          }
        />
      </>
    );
  };

  const adminColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 60,
        disableSortBy: true,
        Cell: customCellGroupNameOrId
      },
      {
        Header: 'Grupo',
        accessor: 'group.name',
        Cell: customCellGroupNameOrId,
        widthColumn: 480,
        disableSortBy: true
      },
      {
        Header: 'Profesor',
        accessor: 'teacher.full_name',
        widthColumn: 250,
        disableSortBy: true,
        Cell: customCellTeacherName
      },
      {
        Header: 'ID Proyecto B2B',
        accessor: 'b2b_project',
        widthColumn: 142,
        disableSortBy: true,
        align: 'center',
        Cell: customCellB2bProject
      },
      {
        Header: 'Día',
        accessor: 'day_sessions',
        placeholder: 'Día 1, día 2, día 3',
        hasSort: true,
        widthColumn: 80,
        sortType: (rowA: any, rowB: any, id: any) => {
          const valueA = Array.isArray(rowA.values[id])
            ? rowA.values[id].join(', ')
            : rowA.values[id];
          const valueB = Array.isArray(rowB.values[id])
            ? rowB.values[id].join(', ')
            : rowB.values[id];

          if (valueA > valueB) return 1;
          if (valueB > valueA) return -1;
          return 0;
        },
        Cell: customCellDaySession
      },
      {
        Header: 'Estado',
        hasSort: true,
        accessor: 'status',
        align: 'center',
        disableFilters: true,
        Cell: customCellStatusTag
      },
      {
        Header: 'Inicio',
        accessor: 'group.start_date',
        align: 'center',
        disableFilters: true,
        Cell: customCellDates
      },
      {
        Header: 'Fin',
        accessor: 'group.end_date',
        align: 'center',
        disableFilters: true,
        Cell: customCellDates
      },
      {
        Header: 'Estudiantes',
        accessor: 'students_number',
        align: 'center',
        disableFilters: true,
        widthColumn: 81
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  const teacherCTCColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        minWidthColumn: 60,
        disableSortBy: true,
        Cell: customCellGroupNameOrId
      },
      {
        Header: 'Grupo',
        accessor: 'group.name',
        Cell: customCellGroupNameOrId,
        widthColumn: 380,
        disableSortBy: true
      },
      {
        Header: 'Profesor',
        accessor: 'teacher.full_name',
        widthColumn: 180,
        disableSortBy: true,
        Cell: customCellTeacherName
      },
      {
        Header: 'Evaluación',
        accessor: 'status_evaluation',
        widthColumn: 152,
        disableSortBy: true,
        align: 'center',
        Cell: customCellEvaluationTag
      },
      {
        Header: 'Vencimiento Evaluación',
        accessor: 'end_date_evaluation',
        widthColumn: 132,
        disableSortBy: true,
        align: 'center',
        Cell: customCellEvaluationDate
      },
      {
        Header: 'Día',
        accessor: 'day_sessions',
        placeholder: 'Día 1, día 2, día 3',
        hasSort: true,
        widthColumn: 80,
        sortType: (rowA: any, rowB: any, id: any) => {
          const valueA = Array.isArray(rowA.values[id])
            ? rowA.values[id].join(', ')
            : rowA.values[id];
          const valueB = Array.isArray(rowB.values[id])
            ? rowB.values[id].join(', ')
            : rowB.values[id];

          if (valueA > valueB) return 1;
          if (valueB > valueA) return -1;
          return 0;
        },
        Cell: customCellDaySession
      },
      {
        Header: 'Estado',
        hasSort: true,
        accessor: 'status',
        align: 'center',
        Cell: customCellStatusTag
      },
      {
        Header: 'Inicio',
        accessor: 'group.start_date',
        align: 'center',
        disableFilters: true,
        Cell: customCellDates
      },
      {
        Header: 'Fin',
        accessor: 'group.end_date',
        align: 'center',
        disableFilters: true,
        Cell: customCellDates
      },
      {
        Header: 'Estudiantes',
        accessor: 'students_number',
        align: 'center',
        disableFilters: true,
        widthColumn: 81
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  const teacherAuxColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        minWidthColumn: 42,
        disableSortBy: true,
        Cell: customCellGroupNameOrId
      },
      {
        Header: 'Grupo',
        accessor: 'group.name',
        classColumn: 'name-group',
        Cell: customCellGroupNameOrId,
        widthColumn: 580,
        disableSortBy: true
      },
      {
        Header: 'Profesor',
        accessor: 'teacher.full_name',
        classColumn: 'name-teacher',
        widthColumn: 152,
        disableSortBy: true,
        Cell: customCellTeacherName
      },
      {
        Header: 'Día',
        accessor: 'day_sessions',
        placeholder: 'Día 1, día 2, día 3',
        hasSort: true,
        widthColumn: 80,
        sortType: (rowA: any, rowB: any, id: any) => {
          const valueA = Array.isArray(rowA.values[id])
            ? rowA.values[id].join(', ')
            : rowA.values[id];
          const valueB = Array.isArray(rowB.values[id])
            ? rowB.values[id].join(', ')
            : rowB.values[id];

          if (valueA > valueB) return 1;
          if (valueB > valueA) return -1;
          return 0;
        },
        Cell: customCellDaySession
      },
      {
        Header: 'Estado',
        hasSort: true,
        accessor: 'status',
        align: 'center',
        Cell: customCellStatusTag,
        disableFilters: true
      },
      {
        Header: 'Inicio',
        accessor: 'group.start_date',
        align: 'center',
        Cell: customCellDates,
        disableFilters: true
      },
      {
        Header: 'Fin',
        accessor: 'group.end_date',
        align: 'center',
        Cell: customCellDates,
        disableFilters: true
      },
      {
        Header: 'Estudiantes',
        accessor: 'students_number',
        align: 'center',
        disableFilters: true,
        widthColumn: 81
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  useEffect(() => {
    const MODAL_KEY = 'lastShownModalDate';
    const lastShown = localStorage.getItem(MODAL_KEY);
    const today = new Date().toDateString();

    if (lastShown !== today && stateQualifications && teacherCTC) {
      openModalEvaluation();
      localStorage.setItem(MODAL_KEY, today);
    }
  }, [stateQualifications]);

  useEffect(() => {
    if (Number(userId) !== 0) {
      getPendingQualification(Number(userId));
    }
  }, []);

  const getPendingQualification = async (userId: number) => {
    try {
      const { data } = await getQualificationForTeacher(userId);
      setStateQualifications(data);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        console.log(error.response?.data);
      }
    }
  };

  const handleModal = async () => {
    setCurrentFilters({ sort: 'end_date_evaluation', direction: 'asc' });
    closeModalEvaluation();
  };

  const handleReviewLater = () => {
    closeModalEvaluation();
  };

  if (isLoading) return <ScreenLoader />;

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage: currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters: currentFilters,
    setLocalCurrentFilters: setLocalCurrentFilters,
    localCurrentFilters: localCurrentFilters,
    totalRows: count,
    totalPages,
    pageSize: size
  };

  return (
    <>
      {Object.keys(finalLocalCurrentFilters).length > 0 ? (
        <section className="guardians_filter__container_courses">
          <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
            Filtro(s) Actual(es):{' '}
          </p>
          {Object.keys(finalLocalCurrentFilters).map(
            (key: string, index, test) => {
              return (
                <>
                  <TagCourses className="guardians_filter__tag">
                    <Typography type="paragraph2" variation="mobile" bold>
                      {`${FILTERS_FIELD_NAMES_TRANSLATIONS.get(key)}: ${
                        finalLocalCurrentFilters[key]
                      }`}
                    </Typography>
                    <ButtonLineal
                      size="small"
                      className="guardians_filter__button_removal"
                      onClick={() => resetFilter(key)}
                    >
                      &#10005;
                    </ButtonLineal>
                  </TagCourses>
                </>
              );
            }
          )}
        </section>
      ) : null}
      <Table
        data={rooms}
        columns={
          userAdmin
            ? adminColumns
            : teacherCTC
              ? teacherCTCColumns
              : teacherAuxColumns
        }
        paginationState={paginationState}
        childSectionHeader={
          userAdmin ? (
            <ButtonOutline size="small" href="/dashboard/rooms/reporte-atrasos">
              Reportes de atraso
            </ButtonOutline>
          ) : null
        }
      />
      <ModalMessageAlert
        className="table-evaluation-student__modal-alert"
        width="sm"
        iconAlert={<IconAlertCircleOutlined fill={colors.warningColor} />}
        title={
          <Typography bold type="paragraph1" variation="desktop" align="center">
            Tienes salones pendientes de evaluación
          </Typography>
        }
        isOpenModal={isOpenModalEvaluation}
        openModal={openModalEvaluation}
        closeModal={closeModalEvaluation}
        body={
          <div className="table-evaluation-student__modal-alert--description-container">
            <Typography type="paragraph2" variation="mobile" align="center">
              Por favor, asegúrate completarlos antes de la fecha de
              vencimiento.
            </Typography>
          </div>
        }
        button1={
          <ButtonFilled onClick={handleModal}>
            Ver salones pendientes
          </ButtonFilled>
        }
        button2={
          <ButtonLineal onClick={handleReviewLater}>Revisar luego</ButtonLineal>
        }
      />
    </>
  );
};

export default TableRoom;
