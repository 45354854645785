/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Button as ButtonMUI, Alert, AlertTitle } from '@material-ui/core';
import {
  Tab,
  TabButton,
  Modal,
  Checkbox,
  Typography
} from 'ctc-component-library';

import './roomDetail.scss';
import {
  RootState,
  AppDispatch,
  useAppDispatch
} from '../../../../store/store';
import { Students } from './Students/Students';
import { Teachers } from './Teachers/Teachers';
import NavRoomDetail from './Nav/NavRoomDetail';
import {
  TEACHER_CTC,
  TEACHER_AUXILIARY,
  ADMIN
} from '../../../../constants/usersType.constants';
import Evaluations from './Evaluations/Evaluations';
import { useModal } from '../../../../hooks/useModal';
import { patchNews } from '../../../../services/news';
import CommentsSection from './Comments/CommentsSection';
import RoomDetailHeader from './Header/RoomDetailHeader';
import useRoomDetail from '../../../../hooks/useRoomDetail';
import Link from '../../../../components/molecules/Link/Link';
import useShowNewsModal from '../../../../hooks/useShowNewsModal';
import IconArrow from '../../../../components/atoms//icons/IconArrow';
import { IScheduleGroup } from '../../../../interfaces/room.interface';
import NoAccess from '../../../../components/organism/NoAccess/NoAccess';
import useStatusStudentsByRoom from '../../../../hooks/useStatusStudents';
import useRoomHeaderDetails from '../../../../hooks/useRoomHeaderDetails';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { setRoom } from '../../../../slices/roomDetailSlice/roomDetailSlice';
import { START_DATE_EVALUATIONS } from '../../../../constants/evaluationsDate.constants';

interface ITabsContent {
  label: string[];
  content: any;
}

interface StatusItem {
  value: string;
  label: string;
}

function RoomDetailV2() {
  const { roomId } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const {
    openModal: openModalShowUpdates,
    closeModal: closeModalShowUpdates,
    isOpenModal: isOpenModalShowUpdates
  } = useModal();
  const user = useSelector((state: RootState) => state.auth.user);
  const userRole = user?.permission?.name;
  const qtySessions = useRef({ value: 'partial', label: '± 3 sesiones' });
  const [checked, setChecked] = useState(false);
  const [hasAuxTeachers, setHasAuxTeachers] = useState<boolean>(false);
  const [activeTabMain, setActiveTabMain] = useState<number>(0);
  const [activeTabTeachers, setActiveTabTeachers] = useState<number>(0);
  const [activeTabStudents, setActiveTabStudents] = useState<number>(0);
  const [activeTabComments, setActiveTabComments] = useState<number>(0);
  const [tabsContentStudents, setTabsContentStudents] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentTeachers, setTabsContentTeachers] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentComments, setTabsContentComments] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [shouldRefetchDetailHeader, setShouldRefetchDetailHeader] =
    useState<boolean>(false);
  const [dateEvaluations, setDateEvaluations] = useState({});

  const refetchAllDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
    setShouldRefetchDetailHeader(!shouldRefetchDetailHeader);
  };

  const refetchDetailHeader = () => {
    setShouldRefetchDetailHeader(!shouldRefetchDetailHeader);
  };

  const {
    data: dataNews,
    isLoading: isLoadingNews,
    isError: isErrorNews,
    fetchNews
  } = useShowNewsModal({
    context: 'room',
    status: 'active'
  });

  const {
    data: dataStatusStudents,
    isLoading: isLoadingStatusStudents,
    isError: isErrorStatusStudents,
    fetchStatusStudentsByRoom
  } = useStatusStudentsByRoom({ roomId: Number(roomId) });

  const {
    data: dataRoomHeader,
    isLoading: isLoadingHeader,
    isError: isErrorHeader,
    error: errorHeader
  } = useRoomHeaderDetails({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetchDetailHeader
  });

  const {
    data: dataRoom,
    isLoading,
    isError,
    error
  } = useRoomDetail({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  useEffect(() => {
    if (dataNews && dataNews?.notification && dataNews?.notification?.id) {
      openModalShowUpdates();
    }
  }, [dataNews]);

  useEffect(() => {
    if (dataRoom) {
      dispatch(setRoom(dataRoom));
      if (START_DATE_EVALUATIONS !== '') {
        setDateEvaluations(
          moment(dataRoom?.date_session[0]?.start_date).format('YYYY-MM-DD') >=
            moment(START_DATE_EVALUATIONS).format('YYYY-MM-DD')
        );
      }

      setHasAuxTeachers(!!(dataRoom?.auxiliar_teacher.length > 0));

      const studentsLabels = ['Asistencias'];
      const studentsContents = [
        <Students
          key="tab-content-students"
          scheduleByRoom={dataRoomHeader?.schedule[0] ?? ({} as IScheduleGroup)} // no se usa
          refetch={refetchAllDetailRoom}
          room={dataRoom}
          refetchDetailHeader={refetchDetailHeader}
          fetchStatusStudentsByRoom={fetchStatusStudentsByRoom}
          qtySessions={qtySessions}
        />
      ];

      let teachersLabels = ['Asistencia Crack the Code'];
      let teachersContents = [
        <Teachers
          setActiveTabMain={setActiveTabMain}
          setActiveTabTeachers={setActiveTabTeachers}
          key="tab-content-teachers-ctc"
          refetch={refetchAllDetailRoom}
          room={dataRoom}
          type="teacherCTC"
        />
      ];

      const commentsLabels = ['Apuntes'];
      const commentsContents = [
        <CommentsSection
          key="tab-content-comments"
          refetchRoomDetails={refetchAllDetailRoom}
          roomInfo={dataRoom}
          setActiveTabMain={setActiveTabMain}
        />
      ];

      if (dataRoom?.business_type !== 'B2B') {
        studentsLabels.push('Evaluaciones');
        studentsContents.push(
          <Evaluations
            key="tab-content-evaluations"
            refetch={refetchAllDetailRoom}
            room={dataRoom}
          />
        );
      } else {
        if (userRole !== TEACHER_AUXILIARY) {
          teachersLabels.push('Asistencia externos');
          teachersContents.push(
            <Teachers
              setActiveTabMain={setActiveTabMain}
              setActiveTabTeachers={setActiveTabTeachers}
              key="tab-content-teachers-aux"
              refetch={refetchAllDetailRoom}
              room={dataRoom}
              type="AuxiliaryTeacher"
            />
          );

          if (userRole === TEACHER_CTC && hasAuxTeachers) {
            setActiveTabTeachers(1);
          }
        } else {
          teachersLabels = ['Asistencia externos'];
          teachersContents = [
            <Teachers
              setActiveTabMain={setActiveTabMain}
              setActiveTabTeachers={setActiveTabTeachers}
              key="tab-content-teachers-aux"
              refetch={refetchAllDetailRoom}
              room={dataRoom}
              type="AuxiliaryTeacher"
            />
          ];
        }
      }

      setTabsContentStudents({
        label: studentsLabels,
        content: studentsContents
      });

      setTabsContentTeachers({
        label: teachersLabels,
        content: teachersContents
      });

      setTabsContentComments({
        label: commentsLabels,
        content: commentsContents
      });
    }
  }, [dataRoom]);

  if (isLoadingHeader) return <ScreenLoader />;
  if (
    isError &&
    error?.code === 403 &&
    (userRole === TEACHER_CTC || userRole === TEACHER_AUXILIARY)
  ) {
    return (
      <NoAccess
        button={{
          to: '/dashboard/rooms',
          message: 'Volver a salones'
        }}
        errorMessage="No tienes acceso a esta sección"
      />
    );
  }
  if (isError || !dataRoom) {
    return (
      <>
        <Link
          className="room-detail-header-v2__button-back"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
          to="/dashboard/rooms"
        >
          Regresar a Salones
        </Link>

        <Alert
          severity="error"
          variant="outlined"
          action={
            <ButtonMUI
              color="inherit"
              size="small"
              onClick={refetchAllDetailRoom}
            >
              Reintentar
            </ButtonMUI>
          }
        >
          <AlertTitle>Error</AlertTitle>
          No se pudo obtener la información del salón. Por favor reintente.
        </Alert>
      </>
    );
  }

  const onChangeTabButton = (tabIndex: number) => {
    if (tabIndex === 0) {
      setActiveTabStudents(0);
    } else if (tabIndex === 1) {
      if (userRole === TEACHER_CTC && hasAuxTeachers) {
        setActiveTabTeachers(1);
      } else {
        setActiveTabTeachers(0);
      }
    } else if (tabIndex === 2) {
      setActiveTabComments(0);
    }
  };

  const updateVisibilityModalNews = async () => {
    try {
      const notificationId: number = dataNews?.id;
      const status = checked ? 'hidden' : 'active';

      await patchNews(notificationId, status);
      closeModalShowUpdates();
    } catch (error) {
      console.log('error', error);
    }
  };

  const statusItems: StatusItem[] = [
    { label: 'Estudiantes Activos', value: 'active' },
    { label: 'Riesgo', value: 'risk' },
    { label: 'Abandono', value: 'inactive' }
  ];

  return (
    <>
      <div style={{ position: 'relative' }}>
        <NavRoomDetail dataRoomHeader={dataRoomHeader} />
        <div className="room-detail-v2__container">
          <RoomDetailHeader
            dataRoomHeader={dataRoomHeader}
            room={dataRoom}
            refetch={refetchAllDetailRoom}
            setActiveTabMain={setActiveTabMain}
            setActiveTabTeachers={setActiveTabTeachers}
          />
          <div className="room-detail-v2__body">
            <TabButton
              key={`main-tab-${activeTabMain}`}
              defaultActiveKey={activeTabMain}
              setDefaultActiveKey={setActiveTabMain}
              tabs={[
                {
                  label: 'Estudiantes',
                  content: (
                    <div style={{ width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography variant="body1">
                          <strong>Estudiantes</strong>
                        </Typography>
                        <div>
                          <div style={{ display: 'flex' }}>
                            {!isLoadingStatusStudents &&
                            dataStatusStudents.length > 0
                              ? dataStatusStudents.map((qtyStudent, index) => {
                                  const value = statusItems[index].value;
                                  const label = statusItems[index].label;

                                  return (
                                    <>
                                      <div>
                                        <Typography variant="body3">
                                          {label}:&nbsp;
                                          <strong>
                                            {qtyStudent[`${value}`]}
                                          </strong>
                                        </Typography>
                                      </div>
                                      {index < dataStatusStudents.length - 1 ? (
                                        <div
                                          style={{
                                            marginLeft: '5px',
                                            marginRight: '5px'
                                          }}
                                        >
                                          <Typography variant="body3">
                                            |
                                          </Typography>
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                      <br />
                      <Tab
                        key={`students-tab-${activeTabStudents}`}
                        defaultActiveKey={activeTabStudents}
                        setDefaultActiveKey={setActiveTabStudents}
                        tabs={tabsContentStudents?.label.map((_, index) => ({
                          label: tabsContentStudents?.label[index],
                          content: tabsContentStudents?.content[index]
                        }))}
                      />
                    </div>
                  )
                },
                {
                  label: 'Profesores',
                  content: (
                    <Tab
                      key={`teachers-tab-${activeTabTeachers}`}
                      defaultActiveKey={activeTabTeachers}
                      setDefaultActiveKey={setActiveTabTeachers}
                      tabs={tabsContentTeachers?.label.map((_, index) => ({
                        label: tabsContentTeachers?.label[index],
                        content: tabsContentTeachers?.content[index]
                      }))}
                    />
                  )
                },
                {
                  label: 'Comentarios',
                  content: (
                    <Tab
                      key={`comments-tab-${activeTabComments}`}
                      defaultActiveKey={activeTabComments}
                      setDefaultActiveKey={setActiveTabComments}
                      tabs={tabsContentComments.label.map((_, index) => ({
                        label: tabsContentComments?.label[index],
                        content: tabsContentComments?.content[index]
                      }))}
                    />
                  )
                }
              ]}
              onChange={onChangeTabButton}
            />
          </div>
        </div>
      </div>
      <Modal
        type="small"
        title="¡Novedades!"
        description={
          userRole === ADMIN
            ? `Ahora podrás ver si la sesión ha sido cancelada, junto con su motivo correspondiente. 
				<br /><br />
				También hemos reorganizado la información del salón para mayor claridad.
				<br /><br />
				Además, tendrás la opción de identificar fácilmente las sesiones en riesgo de cancelación, señaladas con el ícono ⚠️.`
            : `Ahora podrás ver si la sesión fue cancelada, junto con el motivo correspondiente. <br /><br /> Además, tendrás la opción de identificar las sesiones con riesgo de cancelación, marcadas con el ícono  -⚠️-`
        }
        bodyContent={
          <Checkbox
            id="item-update"
            checked={checked}
            onChange={() => setChecked(!checked)}
          >
            No volver a mostrar este mensaje
          </Checkbox>
        }
        primaryButtonText="Aceptar"
        handlePrimaryButton={
          checked
            ? () => updateVisibilityModalNews()
            : () => closeModalShowUpdates()
        }
        handleClose={closeModalShowUpdates}
        isModalOpen={
          dataNews?.notification?.name === 'SessionCancellation'
            ? isOpenModalShowUpdates
            : false
        }
        linkButtonUnderline
        primaryButtonVariant="neutral"
        customClassName="room-detail-v2__modal-news-session-cancellation"
      />
    </>
  );
}

export default RoomDetailV2;
