import React from 'react';
import dayjs from 'dayjs';
import {
  Table,
  Icon,
  Tooltip,
  Typography,
  Dropdown,
  Button,
  TooltipWrapper,
  Modal
} from 'ctc-component-library';
import { toast } from 'react-hot-toast';

import './ModalZoomCredentials.scss';
interface ModalZoomCredentialsProps {
  professorUrl: string;
  licenseEmail: string;
  zoomId: string;
  licensePassword: string;
  meetingPassword: string;
  studentProfessorUrl: string;
  datetime: any;
  onClick: () => void;
  roomId: any;
}

export const ModalZoomCredentials: React.FC<ModalZoomCredentialsProps> = ({
  professorUrl,
  licenseEmail,
  zoomId,
  roomId,
  licensePassword,
  meetingPassword,
  studentProfessorUrl,
  datetime,
  onClick
}) => {
  const copyToClipboard = (value: any) => {
    if (value) {
      toast.success(`copiado...`);
      navigator.clipboard.writeText(`${value}`);
    } else toast.error('No existe valor');
  };

  const instanceBaseURL = process.env.REACT_APP_CTC_BACKOFFICE_URL;
  const urlTeacher = `${instanceBaseURL}/class-redirect?room=${roomId}&type=teacher`;
  const urlStudent = `${instanceBaseURL}/class-redirect?room=${roomId}&type=student`;

  const formattedDate = dayjs(datetime?.date).format('DD/MM/YYYY');

  return (
    <div className="zoom-credentials">
      <div className="zoom-credentials__content-title">
        <Typography variant="h4">Credenciales de Zoom</Typography>
      </div>
      <div className="zoom-credentials__content-body">
        <Typography variant="body4">
          Las credenciales pueden variar en cada sesión, no olvides de consultar
          esta información para poder ingresar a las clases
        </Typography>
      </div>
      <div className="zoom-credentials__content-body-2">
        <Typography variant="body4">
          Credenciales para la próxima sesión activa el día:{' '}
          <strong>
            {datetime?.day} {formattedDate} - {datetime?.start_time_formatted}
          </strong>
        </Typography>
      </div>
      <div className="zoom-credentials__content">
        <div className="zoom-credentials__content-column">
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>URL del profesor</strong>
            </Typography>

            <div className="zoom-credentials__content-column-group-row">
              <Typography variant="body5">{urlTeacher}</Typography>
              <span
                onClick={() => {
                  copyToClipboard(urlTeacher);
                }}
              >
                <Icon iconName="content_copy" />
              </span>
            </div>
          </div>
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>Contraseña licencia</strong>
            </Typography>

            <div className="zoom-credentials__content-column-group-row">
              <Typography variant="body5">{licensePassword}</Typography>
              <span
                onClick={() => {
                  copyToClipboard(licensePassword);
                }}
              >
                <Icon iconName="content_copy" />
              </span>
            </div>
          </div>
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>Contraseña de la reunión</strong>
            </Typography>
            <div className="zoom-credentials__content-column-group-row">
              <Typography variant="body5">{meetingPassword}</Typography>
              <span
                onClick={() => {
                  copyToClipboard(meetingPassword);
                }}
              >
                <Icon iconName="content_copy" />
              </span>
            </div>
          </div>
        </div>
        <div className="zoom-credentials__content-column">
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>Licencia</strong>
            </Typography>
            <Typography variant="body5">{licenseEmail}</Typography>
          </div>
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>Zoom ID</strong>
            </Typography>
            <Typography variant="body5">{zoomId}</Typography>
          </div>
          <div className="zoom-credentials__content-column-group">
            <Typography variant="body5">
              <strong>Zoom estudiantes y profesor IE</strong>
            </Typography>
            <div className="zoom-credentials__content-column-group-row">
              <Typography variant="body5">{urlStudent}</Typography>
              <span
                onClick={() => {
                  copyToClipboard(urlStudent);
                }}
              >
                <Icon iconName="content_copy" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Button
        type="primary"
        primaryVariant="neutral"
        // fontVariant="button1"
        customBtnClassName="zoom-credentials__button"
        text="Aceptar"
        onClick={onClick}
      />
    </div>
  );
};
