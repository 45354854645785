import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from 'ctc-component-library';

import './ScheduleChange.scss';
import Loader from '../../../../../components/atoms/Loader/Loader';

export const ScheduleChangeLoader: React.FC = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();

  const goToRoom = () => {
    navigate(`/dashboard/rooms/${roomId}`);
  };

  setTimeout(() => {
    goToRoom();
  }, 2000);

  return (
    <div className="schedule-change-loader">
      <span className="schedule-change-loader__icon-wrap">
        <Loader customClassName="schedule-change-loader__icon" />
      </span>
      <Typography variant="h5" className="schedule-change-loader__title">
        Estamos procesando los cambios del salón.
      </Typography>
      <span className="schedule-change-loader__description-wrap">
        <Typography
          variant="body4"
          className="schedule-change-loader__description"
        >
          Esto puede tomar unos segundos. Te notificaremos por Discord el status
          de la actualización.
        </Typography>
      </span>
    </div>
  );
};
