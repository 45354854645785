import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconLoader: React.FC<Props> = ({
  className,
  fill = '#303333',
  dataTestid = 'icon-add'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="progress_activity">
        <mask
          id="mask0_2604_9384"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2604_9384)">
          <path
            id="progress_activity_2"
            d="M8.3335 13.3333C7.65016 13.3333 7.00433 13.2021 6.396 12.9396C5.78766 12.6771 5.25641 12.3187 4.80225 11.8646C4.34808 11.4104 3.98975 10.8791 3.72725 10.2708C3.46475 9.66248 3.3335 9.01665 3.3335 8.33331C3.3335 7.64165 3.46475 6.99373 3.72725 6.38956C3.98975 5.7854 4.34808 5.25623 4.80225 4.80206C5.25641 4.3479 5.78766 3.98956 6.396 3.72706C7.00433 3.46456 7.65016 3.33331 8.3335 3.33331C8.47516 3.33331 8.59391 3.38123 8.68975 3.47706C8.78558 3.5729 8.8335 3.69165 8.8335 3.83331C8.8335 3.97498 8.78558 4.09373 8.68975 4.18956C8.59391 4.2854 8.47516 4.33331 8.3335 4.33331C7.22516 4.33331 6.28141 4.7229 5.50225 5.50206C4.72308 6.28123 4.3335 7.22498 4.3335 8.33331C4.3335 9.44165 4.72308 10.3854 5.50225 11.1646C6.28141 11.9437 7.22516 12.3333 8.3335 12.3333C9.44183 12.3333 10.3856 11.9437 11.1647 11.1646C11.9439 10.3854 12.3335 9.44165 12.3335 8.33331C12.3335 8.19165 12.3814 8.0729 12.4772 7.97706C12.5731 7.88123 12.6918 7.83331 12.8335 7.83331C12.9752 7.83331 13.0939 7.88123 13.1897 7.97706C13.2856 8.0729 13.3335 8.19165 13.3335 8.33331C13.3335 9.01665 13.2022 9.66248 12.9397 10.2708C12.6772 10.8791 12.3189 11.4104 11.8647 11.8646C11.4106 12.3187 10.8814 12.6771 10.2772 12.9396C9.67308 13.2021 9.02516 13.3333 8.3335 13.3333Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconLoader;
