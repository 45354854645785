import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';

import './nextClass.scss';
import colors from '../../atoms/Colors';
import { PATCH } from '../../../services/api/api';
import { clearString } from '../../../utils/utils';
import IconArrow from '../../atoms/icons/IconArrow';
import IconClock from '../../atoms/icons/IconClock';
import FloatingMenu from '../../molecules/FloatingMenu';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import ItemFloatingMenu from '../../molecules/ItemFloatingMenu';
import PERMISSIONS from '../../../constants/permission.constants';
import { getAccessCookie } from '../../../helpers/cookies.helpers';
import IconArrowCollapse from '../../atoms/icons/IconArrowCollapse';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import IconOutlinedInformation from '../../atoms/icons/IconOutlinedInformation';
import {
  TEACHER_CTC,
  TEACHER_AUXILIARY
} from '../../../constants/usersType.constants';

interface Zoom {
  zoom_id: string;
  url_alumno: string;
}
interface Group {
  id: number;
  name: string;
}
interface Teacher {
  id: number;
  full_name: string;
}
interface NextClass {
  room_id: number;
  group: Group;
  teacher: Teacher;
  zoom_data: Zoom;
  status: string;
  session: string;
  end_time: string;
  course_wiki_url: string | null;
}

interface Props {
  user: any;
  children?: any;
}

const NextClass: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const teacherRoles = [TEACHER_CTC, TEACHER_AUXILIARY];
  const [isOpen, setIsOpen] = useState(false);
  const [nextClass, setNextClass] = useState([]);

  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm:ss'));
  const instanceBaseURL = process.env.REACT_APP_CTC_BACKOFFICE_URL;

  useEffect(() => {
    // Función para actualizar la hora
    const tick = () => {
      setCurrentTime(moment().format('HH:mm:ss')); // Actualizar el estado con la hora actual formateada
    };

    // Establecer un timer para llamar a tick() cada segundo
    const timerID = setInterval(tick, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    if (teacherRoles.includes(user?.permission?.name)) {
      setIsOpen(true);
      getNextClass();
    }
  }, [user]);

  const checkIfTheClassEnded = (endTimeClass: string): boolean => {
    // Asegurarse de que ambos tiempos están siendo creados correctamente como objetos moment
    const momentCurrentTime = moment(currentTime, 'HH:mm:ss');
    const momentEndTimeClass = moment(endTimeClass, 'HH:mm:ss');

    // Usar isAfter para verificar si momentTime1 es después de momentTime2
    return momentCurrentTime.isAfter(momentEndTimeClass);
  };

  const getNextClass = async () => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_CTC_BACKEND_URL}/teachers/${
          user!.id
        }/rooms/next_session`,
        { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
      );

      setNextClass(data);
    } catch (error) {
      console.log('somethings went wrong!', error);
    }
  };

  const refreshMeetingLink = useMutation(
    (zoom_meeting_id: string) =>
      PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, { zoom_meeting_id }),
    { retry: false }
  );

  const handleRefreshMeetingLink = async (zoomId: any) => {
    await toast.promise(
      refreshMeetingLink
        .mutateAsync(clearString(zoomId))
        .then(({ data: { start_url } }: any) => {
          setTimeout(() => {
            window.open(start_url, '_blank');
          }, 1000);
        }),
      {
        loading: 'Cargando...',
        success: 'Vamos a Zoom!',
        error: `Ha ocurrido un error. Inicie la clase con la cuenta correcta` /*  <${room.licensed_user_email}> */
      }
    );
  };

  const goToZoomRedirectView = (roomId: any) => {
    const isAuxTeacher = user?.permission?.name === TEACHER_AUXILIARY;
    const type = isAuxTeacher ? 'student' : 'teacher';

    if (roomId) {
      window.open(`/class-redirect?room=${roomId}&type=${type}`, '_blank');
    }
  };

  return (
    <>
      <div
        className={`next-class next-class__${
          teacherRoles.includes(user?.permission?.name) && isOpen
            ? 'height-open'
            : 'height-close'
        }`}
      >
        <section className="next-class__section-title">
          <div className="next-class__title">
            <IconClock />
            <Typography type="h3" variation="mobile" bold>
              Tus clases de hoy (
              {nextClass && nextClass.length ? nextClass.length : 0})
            </Typography>
            <div className="next-class__show-tooltip">
              <IconOutlinedInformation />
              <Typography
                className="next-class__tooltip"
                type="small-12"
                variation="mobile"
                color="#fff"
              >
                No se mostrarán en la lista las clases canceladas
              </Typography>
            </div>
          </div>
          {teacherRoles.includes(user?.permission?.name) &&
          nextClass &&
          nextClass.length > 0 ? (
            <div
              className={`next-class__title `}
              onClick={() => setIsOpen(!isOpen)}
            >
              <IconArrowCollapse
                className={`next-class__icon-collapse next-class__icon-collapse--${
                  isOpen ? 'open' : 'close'
                }`}
              />
            </div>
          ) : (
            <div className="next-class__title">
              <IconArrowCollapse className="next-class__icon-collapse next-class__icon-collapse--close next-class__icon-collapse--disabled" />
            </div>
          )}
        </section>
        {isOpen ? (
          <>
            <hr />
            {nextClass && nextClass.length > 0 ? (
              <section className="next-class__container-details">
                <div className="next-class__detail-next-class">
                  <Typography type="paragraph1" variation="mobile" bold>
                    Grupo
                  </Typography>
                  <Typography type="paragraph1" variation="mobile" bold>
                    Profesor
                  </Typography>
                  <div></div>
                </div>
                <div className="next-class__detail-next-class">
                  {nextClass.map((todayClass: any, key: number) => (
                    <React.Fragment key={`list-class-${key}`}>
                      <Typography
                        type="paragraph2"
                        variation="mobile"
                        color={
                          checkIfTheClassEnded(todayClass.end_time)
                            ? colors.gris02
                            : colors.gris05
                        }
                      >
                        {todayClass?.group?.name}
                      </Typography>
                      <Typography
                        type="paragraph2"
                        variation="mobile"
                        color={
                          checkIfTheClassEnded(todayClass.end_time)
                            ? colors.gris02
                            : colors.gris05
                        }
                      >
                        {todayClass?.teacher?.full_name}
                      </Typography>
                      <section className="next-class__actions">
                        <ButtonFilled
                          size="small"
                          width="132px"
                          iconRight={<IconArrow fill="#fff" />}
                          disabled={
                            !todayClass?.zoom_data?.zoom_id ||
                            checkIfTheClassEnded(todayClass?.end_time)
                              ? true
                              : false
                          }
                          onClick={() => {
                            goToZoomRedirectView(todayClass?.room_id);
                          }}
                        >
                          Ir a Zoom
                        </ButtonFilled>
                        <ButtonOutline
                          size="small"
                          width="132px"
                          disabled={!todayClass?.room_id ? true : false}
                          onClick={() =>
                            navigate(`/dashboard/rooms/${todayClass.room_id}`)
                          }
                        >
                          Ver Salón
                        </ButtonOutline>
                        {/* {todayClass && todayClass.course_wiki_url ? (
													<div className="next-class__container-tooltip">
														<LinkRouter to={todayClass?.course_wiki_url}>
															<IconWiki className="next-class__icon-wiki" />
														</LinkRouter>
														<section className="next-class__tooltip next-class__tooltip--wiki">
															Ir a Wiki
														</section>
													</div>
												) : (
													<div className="next-class__container-tooltip">
														<IconWiki
															className="next-class__icon-wiki next-class__icon-wiki--disabled"
															fill="#bcbcbc"
														/>
														<section className="next-class__tooltip next-class__tooltip--wiki">
															Ir a Wiki
														</section>
													</div>
												)} */}
                        <FloatingMenu
                          items={[
                            <>
                              {todayClass && todayClass.zoom_data ? (
                                <ItemFloatingMenu
                                  onClick={() => {
                                    const isAuxTeacher =
                                      user?.permission?.name ===
                                      TEACHER_AUXILIARY;
                                    const type = isAuxTeacher
                                      ? 'student'
                                      : 'teacher';
                                    const url = `${instanceBaseURL}/class-redirect?room=${todayClass?.room_id}&type=${type}`;
                                    toast.success('URL de Zoom copiado...');
                                    navigator.clipboard.writeText(url);
                                  }}
                                >
                                  Copiar URL de Zoom
                                </ItemFloatingMenu>
                              ) : (
                                <ItemFloatingMenu disabled>
                                  Copiar URL de Zoom
                                </ItemFloatingMenu>
                              )}
                            </>,
                            <>
                              {user?.permission[PERMISSIONS.roomListAll] &&
                              todayClass &&
                              todayClass.group ? (
                                <ItemFloatingMenu>
                                  <LinkRouter
                                    to={`/dashboard/groups/${todayClass.group.id}`}
                                  >
                                    Ver Grupo
                                  </LinkRouter>
                                </ItemFloatingMenu>
                              ) : (
                                <ItemFloatingMenu disabled>
                                  Ver Grupo
                                </ItemFloatingMenu>
                              )}
                            </>
                          ]}
                        />
                      </section>
                    </React.Fragment>
                  ))}
                </div>
              </section>
            ) : (
              <section className="next-class__without-classes">
                <Typography
                  type="paragraph2"
                  variation="mobile"
                  color={colors.gris03}
                >
                  ¡No tienes ninguna clase programada para hoy!
                </Typography>
              </section>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default NextClass;
