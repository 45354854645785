import { Button, Toggle, Typography } from 'ctc-component-library';

interface props {
  onClick: () => void;
  checkedToggle: boolean;
  onChangeToggle: (checked: boolean) => void;
  isVisibleButton?: boolean;
}

export const ContentCancellationRisk = ({
  onClick,
  checkedToggle,
  onChangeToggle,
  isVisibleButton = true
}: props) => (
  <div className="content-cancellation-risk__content">
    <div className="content-cancellation-risk__content--container-risk">
      <Typography
        className="content-cancellation-risk__content--container-risk--toogle-label"
        variant="body4"
      >
        Riesgo de cancelación
      </Typography>
      <Toggle checked={checkedToggle} onChange={onChangeToggle} />
    </div>
    {isVisibleButton ? (
      <Button
        customBtnClassName="content-cancellation-risk__content--btn-cancel"
        type="tertiary"
        text="Cancelar sesión"
        onClick={onClick}
      />
    ) : null}
  </div>
);
