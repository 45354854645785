/* eslint-disable complexity */
// Axios
import axios, { AxiosError } from 'axios';

// Helpers
import {
  ITeacherAvailabilityV2,
  ITeacherProfileV2
} from '../interfaces/teacher.interface';
import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

const statusMapping: Record<string, string> = {
  activo: 'activo',
  inactivo: 'inactivo',
  desactivando: 'desactivando',
  none: 'none'
};

const modalityMapping: Record<string, string> = {
  locador: 'locator',
  'por horas': 'self',
  'part-time': 'part_time'
};

//? TEACHERS
export const getTeachersByAvailabilityV2 = async (
  page: number,
  size: number,
  id?: string,
  name?: string,
  last_name?: string,
  status?: string,
  modality?: string,
  email?: string
) => {
  try {
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('size', size.toString());

    if (id !== undefined && id !== '') {
      params.append('id', id.toString());
    }

    if (name !== undefined && name !== '') {
      params.append('name', name.toString());
    }

    if (last_name !== undefined && last_name !== '') {
      params.append('last_name', last_name.toString());
    }

    if (status !== undefined && status !== '') {
      const value = statusMapping[status.toLowerCase()] || '';
      if (value !== '') {
        params.append('status', value.toString());
      }
    }

    if (modality !== undefined && modality !== '') {
      const value = modalityMapping[modality.toLowerCase()] || '';
      if (value !== '') {
        params.append('modality', value.toString());
      }
    }

    if (email !== undefined && email !== '') {
      params.append('email', email.toUpperCase().toString());
    }

    const url = `${instanceBaseURL}/backoffice/v2/teacher-list/?${params.toString()}`;

    const response = await axios.get<ITeacherAvailabilityV2[]>(url, {
      headers: { Authorization: `Bearer ${getAccessCookie()}` }
    });
    return response.data;
  } catch (err) {
    const error: AxiosError = err as AxiosError;
    return error.response?.data.detail;
  }
};

export const getTeacherProfileByIdV2 = async (teacherId: number) => {
  const response = await axios.get<ITeacherProfileV2>(
    `${instanceBaseURL}/teacher-profile/v2/${teacherId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response.data;
};

export const putTeacherProfileV2 = async (
  teacherId: number,
  dataProfile: any
) => {
  const response = await axios.put(
    `${instanceBaseURL}/teacher-profile/v2/${teacherId}/`,
    dataProfile,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postSkillsTeacher = async (info: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/teacher/add-courses/`,
    info,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postEtarioTeacher = async (info: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/teacher/agegroups/`,
    info,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getEtarioTeacher = async () => {
  const response = await axios.get(`${instanceBaseURL}/teacher/agegroups/`, {
    headers: { Authorization: `Bearer ${getAccessCookie()}` }
  });
  return response;
};

export const postDeleteSkillsTeacher = async (info: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/teacher/delete-course/`,
    info,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postDeleteEtarioTeacher = async (info: any) => {
  const response = await axios.delete(`${instanceBaseURL}/teacher/agegroups/`, {
    headers: { Authorization: `Bearer ${getAccessCookie()}` },
    data: info
  });
  return response;
};

export const getCoursesTrained = async () => {
  const response = await axios.get(
    `${instanceBaseURL}/course-list/v2/?no-page`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getKnowledges = async () => {
  const response = await axios.get(
    `${instanceBaseURL}/teacher/knowledges/?no-page`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getSubknowledges = async () => {
  const response = await axios.get(
    `${instanceBaseURL}/teacher/subknowledges/?no-page`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateTeacher = async (info: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/account/register-teacher/`,
    info,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const putTeacherAvailability = async (
  teacherId: number,
  dataProfile: any
) => {
  const response = await axios.put(
    `${instanceBaseURL}/account-teacher/teacher-availability-update/${teacherId}/`,
    dataProfile,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateAuxiliarTeacher = async (info: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/backoffice/room/auxiliar-teacher/`,
    info,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postTeacherAvailability = async (
  teacherId: number,
  dataProfile: any
) => {
  const response = await axios.post(
    `${instanceBaseURL}/account-teacher/teacher-availability/${teacherId}/`,
    dataProfile,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getAuxiliarTeacher = async (email: string) => {
  try {
    const token = getAccessCookie();
    return await axios.get(
      `${instanceBaseURL}/backoffice/room/auxiliar-teacher?email=${email}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const patchDeleteAuxiliarTeacher = async (
  room_id: string,
  teacherId: number
) => {
  try {
    const response = await axios.patch(
      `${instanceBaseURL}/backoffice/room/auxiliar-teacher/${teacherId}/`,
      {
        moodle_access: false,
        room_id: room_id
      },
      { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const syncTeacherWithMoodle = async (teacherId: number) => {
  const response = await axios.get<any>(
    `${instanceBaseURL}/backoffice/moodle-sync/teacher/${teacherId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCheckAvailable = async (payload: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/v1/teacher/check-available/`,
    payload,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchChangeSessionSchedule = async (
  room_id: string,
  payload: any
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/v1/rooms/${room_id}/change-session-schedule/`,
    payload,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
