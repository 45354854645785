import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getAttendanceByStudentFromApi } from '../slices/attendanceSlice/attendanceSlice';

const useAttendanceByStudent = ({
  roomId,
  qtySessions
}: {
  roomId: number;
  qtySessions: any;
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchAttendance = async (getAllSessions = false) => {
    try {
      const actionResult = await dispatch(
        getAttendanceByStudentFromApi({ id: Number(roomId), getAllSessions })
      );

      if (getAttendanceByStudentFromApi.fulfilled.match(actionResult)) {
        // Si la acción fue exitosa, devuelve los datos.
        setData(actionResult.payload);
        setIsLoading(false);
      } else {
        throw new Error(
          typeof actionResult.payload === 'string'
            ? actionResult.payload
            : 'Failed to fetch attendance data'
        );
      }
    } catch (error) {
      setIsError(true);
      console.error('Error fetching attendance data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendance(qtySessions?.current?.value === 'all' ? true : false);
  }, [roomId]);

  return { data, isLoading, isError, fetchAttendance };
};

export default useAttendanceByStudent;
