import React from 'react';
import { Icon } from 'ctc-component-library';

import './toast.scss';

interface ToastProps {
  message: string;
  type: 'success' | 'error' | 'warning';
}

const Toast: React.FC<ToastProps> = ({ message, type }) => {
  const getType = () => {
    switch (type) {
      case 'error':
        return 'cancel';
      default:
        return 'check_circle';
    }
  };

  return (
    <div className={`toast toast__${type}`}>
      <Icon iconName={getType()} customClassName={`toast__icon-${type}`} />
      {message}
    </div>
  );
};

export default Toast;
