import { AxiosError } from 'axios';
import React, { useState } from 'react';

import './modalJustification.scss';
import Modal from '../../atoms/Modal';
import SelectComp from '../SelectComp';
import colors from '../../atoms/Colors';
import ButtonFilled from '../ButtonFilled';
import ButtonOutline from '../ButtonOutline';
import IconAlert from '../../atoms/icons/IconAlert';
import Typography from '../../atoms/Typography/Typography';
import { patchReasonAbsence } from '../../../services/attendance';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';

interface Props {
  student: string;
  listReason: any;
  reasonAbsence: any;
  attendanceId: number;
  setReasonAbsence: any;
  isOpenModal: boolean;
  closeModal: () => void;
  refetchAttendanceDetails: (allSession?: boolean) => void;
  className?: string;
  iconAlert?: any;
  align?: 'center' | 'left';
  typeModal?: 'add' | 'edit';
  dataTestid?: string;
  qtySessions: any;
}

const ModalJustification: React.FC<Props> = ({
  reasonAbsence,
  setReasonAbsence,
  attendanceId,
  student,
  listReason,
  refetchAttendanceDetails,
  isOpenModal,
  closeModal,
  qtySessions,
  className = '',
  iconAlert,
  align = 'left',
  typeModal = 'add',
  dataTestid = 'modal-justification-test-id'
}) => {
  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);

  const handleSendReasonAbsence = async (
    attendanceId: number,
    reasonId: string | number
  ) => {
    try {
      const response = await patchReasonAbsence({ attendanceId, reasonId });
      if (response.status === 200) {
        closeModal();
        refetchAttendanceDetails(
          qtySessions?.current?.value === 'all' ? true : false
        );
        setIsSuccessAlert(true);
        setTimeout(() => {
          setIsSuccessAlert(false);
        }, 5000);
      } else {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setReasonAbsence({ id: 0, name: '' });
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    }
  };

  const handleClickReasonAbsence = (option: any) => {
    setReasonAbsence(option);
  };

  const handleClickReturn = () => {
    closeModal();
    setReasonAbsence({ id: 0, name: '' });
  };

  return (
    <div data-testid={dataTestid}>
      <Modal
        className={`modal-justification ${className}`}
        width="lg"
        isOpen={isOpenModal}
        closeModal={closeModal}
        icon={
          iconAlert === 'alert' ? <IconAlert /> : !iconAlert ? null : iconAlert
        }
        title={
          <Typography
            align={align}
            type="paragraph1"
            color={colors.gris05}
            variation="desktop"
            bold
          >
            Motivo de justificación o falta
          </Typography>
        }
        subtitle={
          <Typography
            type="paragraph1"
            variation="mobile"
            color={colors.gris06}
          >
            Selecciona el motivo de justificación de <b>{student}</b>
          </Typography>
        }
        align={align}
        body={
          <form>
            <div className="modal-justification__modal-container">
              <Typography bold type="paragraph2" variation="mobile">
                Motivo
              </Typography>
              <SelectComp
                size="default"
                itemsDropdown={3}
                onclickDefaultValue={(option: any) =>
                  handleClickReasonAbsence(option)
                }
                options={listReason}
                dividerItems
                value={reasonAbsence}
                placeholder="Seleccionar motivo"
              />
            </div>
          </form>
        }
        buttons={
          <section
            className={`modal-justification__container-buttons modal-justification__container-buttons--align-right`}
          >
            <ButtonOutline
              fullWidth={false}
              size="default"
              onClick={() => handleClickReturn()}
            >
              Volver
            </ButtonOutline>
            <ButtonFilled
              fullWidth={false}
              disabled={!reasonAbsence?.id}
              size="default"
              onClick={() =>
                handleSendReasonAbsence(attendanceId, reasonAbsence?.id)
              }
            >
              Confirmar
            </ButtonFilled>
          </section>
        }
      />
      {isSuccessAlert && (
        <AlertSnackbar severity="success">
          <Typography type="paragraph2" variation="mobile">
            {typeModal === 'add'
              ? 'Agregaste motivo de justificación'
              : 'Modificaste el motivo de justificación'}
          </Typography>
        </AlertSnackbar>
      )}
      {isErrorAlert ? (
        <AlertSnackbar
          message={
            typeModal === 'add'
              ? 'Ocurrió un error al justificar la falta. Vuelve a intentar'
              : 'Ocurrió un error al modificar la justificación. Vuelve a intentar'
          }
          severity="error"
        />
      ) : null}
    </div>
  );
};

export default ModalJustification;
