/* eslint-disable max-lines */
/* eslint-disable complexity */
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {
  Typography,
  colors,
  Button,
  Icon,
  Modal,
  Tooltip
} from 'ctc-component-library';

import './roomDetailHeader.scss';
import {
  ADMIN,
  TEACHER_AUXILIARY
} from '../../../../../constants/usersType.constants';
import { RootState } from '../../../../../store/store';
import { PATCH } from '../../../../../services/api/api';
import { clearString } from '../../../../../utils/utils';
import { useModal } from '../../../../../hooks/useModal';
import { ModalZoomCredentials } from './ModalZoomCredentials/ModalZoomCredentials';
import { IRoomDetailHeaderSimplified } from '../../../../../interfaces/room.interface';
import AuxTeacherActionsModal from '../../../../../components/organism/AuxTeacherActionsModal';

interface Props {
  dataRoomHeader?: IRoomDetailHeaderSimplified;
  room?: any;
  refetch: () => void;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setActiveTabTeachers: React.Dispatch<React.SetStateAction<number>>;
}

type RoomDetail = {
  label: string;
  value: string | number | boolean | null | undefined;
  icon?: boolean;
  disabled?: boolean;
  link?: any;
  iconType?: string;
  valueIcon?: string;
  labelIcon?: boolean;
  valueTooltip?: string;
  linkAction?: any;
};

const RoomDetailHeader: React.FC<Props> = ({
  dataRoomHeader,
  room,
  refetch,
  setActiveTabMain,
  setActiveTabTeachers
}) => {
  const navigate = useNavigate();
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const teacherAuxiliary = user?.permission?.name === TEACHER_AUXILIARY;
  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const {
    openModal: openModalCredentials,
    closeModal: closeModalCredentials,
    isOpenModal: isOpenModalCredentials
  } = useModal();

  const isAdmin = user?.permission?.name === ADMIN;
  const [isVisibleMoreInfo, setIsVisibleMoreInfo] = useState(false);

  const handleZoom = () => {
    const type = teacherAuxiliary ? 'student' : 'teacher';
    const roomId = dataRoomHeader?.id;

    if (roomId) {
      window.open(`/class-redirect?room=${roomId}&type=${type}`, '_blank');
    }
  };

  const handleRecordings = () => {
    if (dataRoomHeader?.google_drive_url_share) {
      toast.success('URL de grabaciones copiado...');
      navigator.clipboard.writeText(dataRoomHeader?.google_drive_url_share);
    } else toast.error('No existe URL de grabaciones');
  };

  const refreshMeetingLink = useMutation(
    (zoom_meeting_id: string) =>
      PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, { zoom_meeting_id }),
    { retry: false }
  );

  const handleRefreshMeetingLink = async () => {
    if (dataRoomHeader?.online_meeting.external_id) {
      await toast.promise(
        refreshMeetingLink
          .mutateAsync(clearString(dataRoomHeader?.online_meeting.external_id))
          .then(({ data: { start_url } }) => {
            setTimeout(() => {
              window.open(start_url, '_blank');
              refetch();
            }, 1000);
          }),
        {
          loading: 'Cargando...',
          success: 'Vamos a Zoom!',
          error: `Ha ocurrido un error. Inicie la clase con la cuenta <${dataRoomHeader?.online_meeting.licensed_user_email}>`
        },
        { success: { icon: <InsertLinkIcon color="primary" /> } }
      );
    }
  };

  const showMoreDetails = () => {
    setIsVisibleMoreInfo(!isVisibleMoreInfo);
  };

  const goToGroup = (groupId: string) => {
    navigate(`/dashboard/groups/${groupId}/rooms`);
  };

  const goToMoodle = (url: string) => {
    window.location.href = url;
  };

  const firstColumn: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name,
      valueIcon: `/dashboard/groups/${dataRoomHeader?.group?.id}/rooms`,
      link: isAdmin ? true : false,
      linkAction: () => {
        goToGroup(`${dataRoomHeader?.group?.id}`);
      },
      valueTooltip: dataRoomHeader?.group.name
    },
    { label: 'Profesor CTC', value: dataRoomHeader?.main_teacher.full_name },
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true,
      iconType: 'copy',
      valueIcon: '',
      link: true,
      linkAction: () => {
        goToMoodle(
          `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        );
      }
    },
    {
      label: 'Zoom',
      value: 'Tus credenciales y Zoom para estudiantes',
      link: true,
      linkAction: openModalCredentials,
      labelIcon: true
    }
  ];

  const secondColumn: RoomDetail[] = [
    {
      label: 'Link a grabaciones',
      value: dataRoomHeader?.google_drive_url_share
        ? dataRoomHeader?.google_drive_url_share
        : '-',
      icon: true,
      disabled: !dataRoomHeader?.google_drive_url_share ?? true
    },
    {
      label: 'Fecha de inicio',
      value: moment(dataRoomHeader?.group.start_date).format('DD/MM/YYYY')
    },
    {
      label: 'Fecha de fin',
      value: moment(dataRoomHeader?.group.end_date).format('DD/MM/YYYY')
    }
  ];

  const firstColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name
    },
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true,
      link: true,
      linkAction: () => {
        goToMoodle(
          `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        );
      }
    },
    {
      label: 'Link a grabaciones',
      value: dataRoomHeader?.google_drive_url_share
        ? dataRoomHeader?.google_drive_url_share
        : '-',
      icon: true,
      disabled: !dataRoomHeader?.google_drive_url_share ?? true
    }
  ];

  const secondColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Profesor CTC',
      value: dataRoomHeader?.main_teacher.full_name
    },
    {
      label: 'Fecha de inicio',
      value: moment(dataRoomHeader?.group.start_date).format('DD/MM/YYYY')
    },
    {
      label: 'Fecha de fin',
      value: moment(dataRoomHeader?.group.end_date).format('DD/MM/YYYY')
    }
  ];

  const renderRoomDetails = (roomDetails: RoomDetail[]) => {
    return (
      <React.Fragment>
        {roomDetails.map((detail, index) => (
          <React.Fragment key={index}>
            <span className="room-detail-header-v2__label-value">
              <Typography
                variant="body5"
                className="room-detail-header-v2__label"
              >
                {detail.label}
              </Typography>
              {detail.labelIcon ? (
                <Tooltip prompText="Los links de acceso a las plataformas y las credenciales pueden variar de sesión a sesión. ">
                  <Icon
                    iconName="info"
                    customClassName="room-detail-header-v2__label-icon"
                    customStyles={
                      detail?.disabled
                        ? { color: '#B9BFBD', fontSize: '16px' }
                        : { fontSize: '16px' }
                    }
                  />
                </Tooltip>
              ) : null}
            </span>
            <span className="room-detail-header-v2__content-value">
              {detail.link ? (
                <>
                  {detail.valueTooltip ? (
                    <Tooltip prompText={detail.valueTooltip}>
                      <button
                        className="room-detail-header-v2__value room-detail-header-v2__value-btn"
                        onClick={detail.linkAction}
                      >
                        <Typography
                          variant="body5"
                          className={`room-detail-header-v2__value ${detail.icon && !detail?.disabled ? 'room-detail-header-v2__underline' : ''}`}
                        >
                          {detail.value}{' '}
                        </Typography>
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      className="room-detail-header-v2__value room-detail-header-v2__value-btn"
                      onClick={detail.linkAction}
                    >
                      <Typography
                        variant="body5"
                        className={`room-detail-header-v2__value ${detail.icon && !detail?.disabled ? 'room-detail-header-v2__underline' : ''}`}
                      >
                        {detail.value}{' '}
                      </Typography>
                    </button>
                  )}
                </>
              ) : (
                <Typography
                  variant="body5"
                  className={`room-detail-header-v2__value ${detail.icon && !detail?.disabled ? 'room-detail-header-v2__underline' : ''}`}
                >
                  {detail.value}{' '}
                </Typography>
              )}

              {detail.icon ? (
                <button
                  disabled={detail?.disabled}
                  className="room-detail-header-v2__container-icon"
                  onClick={() => {
                    if (detail.value) {
                      toast.success(`${detail.label} copiado...`);
                      navigator.clipboard.writeText(`${detail.value}`);
                    } else toast.error('No existe URL de Zoom');
                  }}
                >
                  <Icon
                    iconName="content_copy"
                    customStyles={detail?.disabled ? { color: '#B9BFBD' } : {}}
                  />
                </button>
              ) : null}
            </span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  const BigTypeContent: React.FC = () => (
    <ModalZoomCredentials
      roomId={dataRoomHeader?.id}
      datetime={dataRoomHeader?.schedule}
      professorUrl={dataRoomHeader?.online_meeting.teacher_url || ''}
      licenseEmail={dataRoomHeader?.online_meeting.licensed_user_email || ''}
      zoomId={dataRoomHeader?.online_meeting.external_id || ''}
      licensePassword={
        dataRoomHeader?.online_meeting.licensed_user_password || ''
      }
      meetingPassword={dataRoomHeader?.online_meeting.password || ''}
      studentProfessorUrl={dataRoomHeader?.online_meeting.student_url || ''}
      onClick={closeModalCredentials}
    />
  );

  return (
    <>
      <div className="room-detail-header-v2__content">
        <section className="room-detail-header-v2__container-details">
          <div className="room-detail-header-v2__details">
            <div className="room-detail-header-v2__column">
              {renderRoomDetails(
                teacherAuxiliary ? firstColumnAuxTeacher : firstColumn
              )}
            </div>
            <div className="room-detail-header-v2__column">
              {renderRoomDetails(
                teacherAuxiliary ? secondColumnAuxTeacher : secondColumn
              )}
            </div>
          </div>
          <div className="room-detail-header-v2__container-buttons">
            <div className="room-detail-header-v2__actions">
              <Button
                type="primary"
                primaryVariant="neutral"
                text={
                  !teacherAuxiliary
                    ? `Ir al zoom de profesores CTC`
                    : `Ir a zoom`
                }
                onClick={() => handleZoom()}
                customStyles={{ width: '250px', padding: '12px 16px' }}
              />
            </div>
          </div>
        </section>
        <AuxTeacherActionsModal
          ref={modalRef}
          roomInfo={room}
          refetchDetailRoom={refetch}
          externalTeachers={externalTeachers}
          setExternalTeachers={setExternalTeachers}
          setActiveTabMain={setActiveTabMain}
          setActiveTabTeachers={setActiveTabTeachers}
        />
      </div>
      <Modal
        type="big"
        handleLinkButton={closeModalCredentials}
        handleClose={closeModalCredentials}
        isModalOpen={isOpenModalCredentials}
        bigTypeContent={<BigTypeContent />}
        customClassName="modal-zoom-credentials"
      />
    </>
  );
};

export default RoomDetailHeader;